import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atom_address,
  atom_refresh,
  isGoldToMiningWithdrawShow
} from "../atom";
import { useNavigate } from "react-router-dom";
import { getStores, updateStore } from "../api/crud";
import {
  convertToNewYorkSeven,
  getNewYorkDate,
  percentPaid,
  subtractDates
} from "../utils/utilities";
import moment from "moment";
import { toast } from "react-toastify";
import { IsEmpty } from "../utils/isEmpty";

const GoldToMiningWithdraw = ({ balance }) => {
  const [withdrawal, setWithdrawal] = useState(0);
  const setToMiningWithdrawModal = useSetRecoilState(
    isGoldToMiningWithdrawShow
  );
  const address = useRecoilValue(atom_address);

  // const withdrawHandle = async () => {
  //   if (withdrawal > balance) {
  //     toast("You can't withdraw more than " + balance + " USDT");
  //     return;
  //   }
  //   const clientInfos = await getStores("Client");
  //   if (IsEmpty(clientInfos)) {
  //     return;
  //   }

  //   const clientInfo = Object.entries(clientInfos).filter(
  //     (one) => one[1]?.address === address
  //   );
  //   if (IsEmpty(clientInfo)) {
  //     return;
  //   }
  //   const clientAssetData = await getStores("ClientAsset");
  //   if (clientAssetData === null) {
  //     return;
  //   } else {
  //     const clientAsset = Object.entries(clientAssetData).filter(
  //       (one) => one[1].address === address
  //     );
  //     let new_withdrawable_usdt =
  //       parseFloat(clientAsset[0][1].withdrawable) + parseFloat(withdrawal);
  //     new_withdrawable_usdt = Math.round(new_withdrawable_usdt * 100) / 100;
  //     setWithdrawal(0);
  //     let updateAsset = {
  //       withdrawable: new_withdrawable_usdt
  //     };

  //     let flag2 = updateStore("ClientAsset", clientAsset[0][1].id, updateAsset);
  //     if (flag2) {
  //       let newBalance =
  //         clientInfo[0][1].trade_balance === "" ||
  //         clientInfo[0][1].trade_balance === undefined ||
  //         clientInfo[0][1].trade_balance === null
  //           ? 0
  //           : clientInfo[0][1].trade_balance;
  //       newBalance = parseFloat(newBalance) - parseFloat(withdrawal);
  //       const updated = { ...clientInfo[0][1], trade_balance: newBalance };
  //       await updateStore("Client", clientInfo[0][1].id, updated);
  //       toast("Withdraw Success!");
  //     } else {
  //       toast("Withdraw Failed!");
  //     }
  //   }
  //   setToMiningWithdrawModal(false);
  // };

  const withdrawHandle = async () => {
    if (withdrawal > balance) {
      toast("You can't withdraw more than " + balance + " USDT");
      return;
    }
    const clientInfos = await getStores("Client");
    const clientInfo = Object.entries(clientInfos).filter(
      (one) => one[1]?.address === address
    );
    if (IsEmpty(clientInfo)) {
      return;
    }
    const clientAssetData = await getStores("ClientAsset");
    if (clientAssetData === null) {
      return;
    } else {
      const clientAsset = Object.entries(clientAssetData).filter(
        (one) => one[1].address === address
      );

      let usdt = clientAsset[0][1]?.usdt;
      let withdrawable_usdt = clientAsset[0][1]?.withdrawable;
      let prevDate = new Date(clientAsset[0][1]?.date);
      let interDays = 0;
      let paidMoney = 0;
      const newYorkDate = await getNewYorkDate();
      interDays = subtractDates(newYorkDate, convertToNewYorkSeven(prevDate));
      if (clientInfo[0][1]?.profit_active === "active")
        paidMoney += usdt * 0.005 * interDays;
      else if (clientInfo[0][1]?.profit_active_10 === "active")
        paidMoney += usdt * 0.1 * interDays;
      else if (clientInfo[0][1]?.profit_active_20 === "active")
        paidMoney += usdt * 0.2 * interDays;
      else if (clientInfo[0][1]?.profit_active_50 === "active")
        paidMoney += usdt * 0.5 * interDays;
      else paidMoney += usdt * percentPaid(usdt) * interDays;

      withdrawable_usdt =
        Math.round((withdrawable_usdt + paidMoney) * 100) / 100.0 -
        parseFloat(clientAsset[0][1]?.withdrawn);

      let new_usdt =
        parseFloat(clientAsset[0][1].usdt) + parseFloat(withdrawal);

      const new_withdrawable = withdrawable_usdt + parseFloat(withdrawal);

      const curDate = await getNewYorkDate();

      new_usdt = Math.round(new_usdt * 100) / 100;
      let updateAsset = {
        usdt: new_usdt,
        date: moment(curDate).format("YYYY-MM-DD HH:mm:ss"),
        withdrawable: new_withdrawable
      };
      let flag2 = updateStore("ClientAsset", clientAsset[0][1].id, updateAsset);
      if (flag2) {
        let newBalance =
          clientInfo[0][1].trade_balance === "" ||
          clientInfo[0][1].trade_balance === undefined ||
          clientInfo[0][1].trade_balance === null
            ? 0
            : clientInfo[0][1].trade_balance;
        newBalance = parseFloat(newBalance) - parseFloat(withdrawal);
        const updated = { ...clientInfo[0][1], trade_balance: newBalance };
        await updateStore("Client", clientInfo[0][1].id, updated);
        toast("Withdraw Success!");
      } else {
        toast("Withdraw Failed!");
      }
      setToMiningWithdrawModal(false);
    }
  };

  return (
    <div className="items-center">
      <div className="grid grid-rows-2 grid-cols-3 text-black text-lg font-normal font-sans gap-3">
        <div>
          <p className="text-right">Amount of Withdrawal:</p>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="txt_withdraw"
            name="txt_withdraw"
            className="w-full rounded-sm border p-3"
            placeholder="Input User Id Here."
            value={withdrawal}
            onChange={(e) => {
              setWithdrawal(e.target.value);
            }}
          />
        </div>

        <div className="col-span-3 text-right mt-2">
          <button
            className="rounded-xl bg-gray-800 shadow-md shadow-gray-700 px-6 py-3 text-white hover:bg-gray-500"
            onClick={withdrawHandle}
          >
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoldToMiningWithdraw;
