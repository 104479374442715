import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  atom_address,
  isBuySellModalShow,
  isCountdownModalShow
} from "../atom";
import moment from "moment";
import { formatCurrency, getDateTimeAheadOfSeconds } from "../utils/utilities";
import { saveStore } from "../api/crud";
import axios from "axios";

export const BuySellPane = ({
  type,
  balance,
  direction,
  volume,
  cycle,
  expected
}) => {
  const setBuySellModal = useSetRecoilState(isBuySellModalShow);
  const setCountdownModal = useSetRecoilState(isCountdownModalShow);
  const [buyPrice, setBuyPrice] = useState(0);
  const address = useRecoilValue(atom_address);
  const handleConfirmOrder = () => {
    const curDate = new Date();
    let openTime = moment(curDate).format("YYYY-MM-DD HH:mm:ss");
    let closingTime = getDateTimeAheadOfSeconds(curDate, cycle);
    const data = {
      buyVolume: volume,
      tradeType: type,
      openPrice: buyPrice,
      openTime: openTime,
      closingTime: closingTime,
      cycle: cycle,
      direction: direction,
      address: address,
      tabIndex: -1
    };
    const flag1 = saveStore("GoldTransaction", data);
    setBuySellModal(false);
    setCountdownModal(true);
  };
  useEffect(() => {
    const getPrices = async () => {
      if (type === "XAUUSD") {
        const requestOptions = {
          method: "GET",
          headers: {
            "x-access-token": process.env.REACT_APP_GOLD_API // Replace with your API token
          }
        };

        fetch("https://www.goldapi.io/api/XAU/USD", requestOptions)
          .then((response) => response.json())
          .then((result) => setBuyPrice(result.price))
          .catch((error) => console.log("error", error));
      } else if (type === "ETH") {
        try {
          const response = await axios.get(
            "https://api.coingecko.com/api/v3/simple/price",
            {
              params: {
                ids: "ethereum",
                vs_currencies: "usd"
              }
            }
          );
          setBuyPrice(response.data.ethereum.usd);
        } catch (err) {}
      } else if (type === "BTC") {
        try {
          const response = await axios.get(
            "https://api.coingecko.com/api/v3/simple/price",
            {
              params: {
                ids: "bitcoin",
                vs_currencies: "usd"
              }
            }
          );
          setBuyPrice(response.data.bitcoin.usd);
        } catch (err) {}
      }
      // if (type === "XAUUSD") {
      //   const requestOptions = {
      //     method: "GET",
      //     headers: {
      //       "x-access-token": process.env.REACT_APP_GOLD_API // Replace with your API token
      //     }
      //   };

      //   fetch("https://www.goldapi.io/api/XAU/USD", requestOptions)
      //     .then((response) => response.json())
      //     .then((result) => setBuyPrice(result.price))
      //     .catch((error) => console.log("error", error));
      // } else {
      //   try {
      //     const response = await axios.get(
      //       "https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest",
      //       {
      //         headers: {
      //           "X-CMC_BASIC_API_KEY": "7d5a5306-b7eb-4c71-91b2-bdf822e39e09"
      //         },
      //         params: {
      //           start: 1,
      //           limit: 2,
      //           convert: "USD"
      //         }
      //       }
      //     );

      //     const priceData = response.data.data.find(
      //       (crypto) => crypto.symbol === "ETH"
      //     );
      //     setBuyPrice(priceData.quote.USD.price);
      //   } catch (error) {
      //     console.error("Error fetching the crypto prices:", error);
      //   }
      // }
    };
    getPrices();
  }, [type]);
  return (
    <div className="font-sans bg-gray-900 p-6">
      <div className="grid grid-cols-2 text-center border-t border-t-gray-800 py-2">
        <div>Balance</div>
        <div className="text-sm my-auto">{formatCurrency(balance)} USDT</div>
      </div>
      <div className="grid grid-cols-2 text-center border-t border-t-gray-800 py-2">
        <div>Symbol</div>
        <div className="text-sm my-auto">{type}</div>
      </div>
      <div className="grid grid-cols-2 text-center border-t border-t-gray-800 py-2">
        <div>Direction</div>
        <div
          className={clsx("text-sm my-auto", {
            "text-green-500": direction === "up",
            "text-red-500": direction === "fall"
          })}
        >
          {direction === "up" ? "Buy" : "Sell"}
        </div>
      </div>
      <div className="grid grid-cols-2 text-center border-t border-t-gray-800 py-2">
        <div>Price</div>
        <div className="text-lg my-auto text-green-500 font-bold">
          {formatCurrency(buyPrice)}
        </div>
      </div>
      <div className="grid grid-cols-2 text-center border-t border-t-gray-800 py-2">
        <div>Money</div>
        <div className="text-sm my-auto">{formatCurrency(volume)} USDT</div>
      </div>
      <div className="grid grid-cols-2 text-center border-t border-t-gray-800 border-b border-b-gray-800 py-2">
        <div>Expected</div>
        <div className="text-sm my-auto">{formatCurrency(expected)} USDT</div>
      </div>
      <div
        className="mt-[25px] sm:mb-[50px] bg-green-500 w-3/4 text-center mx-auto text-sm p-2 rounded-lg font-bold cursor-pointer"
        onClick={handleConfirmOrder}
      >
        Confirm order
      </div>
    </div>
  );
};
