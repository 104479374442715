import React, { useEffect } from "react";

const MarketOverview = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      title_raw: "Cryptocurrencies",
      belowLineFillColorGrowing: "rgba(60, 188, 152, 0.05)",
      gridLineColor: "rgba(233, 233, 234, 1)",
      scaleFontColor: "rgba(218, 221, 224, 1)",
      title: "Cryptocurrencies",
      tabs: [
        {
          title_raw: "Ethereum",
          symbols: [
            {
              s: "COINBASE:ETHUSD"
            },
            {
              s: "KRAKEN:ETHEUR"
            },
            {
              s: "KRAKEN:ETHGBP"
            },
            {
              s: "KRAKEN:ETHJPY"
            },
            {
              s: "POLONIEX:ETHBTC"
            },
            {
              s: "WEX:ETHLTC"
            }
          ],
          title: "Ethereum"
        }
      ],
      plotLineColorFalling: "rgba(255, 74, 104, 1)",
      plotLineColorGrowing: "rgba(60, 188, 152, 1)",
      showChart: true,
      title_link: "/markets/cryptocurrencies/prices-all/",
      locale: "en",
      symbolActiveColor: "rgba(242, 250, 254, 0.1)", // selected background color
      symbolShortNameColor: "rgba(129, 140, 248, 1)",
      symbolDescriptionColor: "rgba(129, 140, 248, 1)",
      symbolSeparatorColor: "rgba(255, 0, 255, 1)",
      tickerColorFalling: "rgba(129, 140, 248, 1)",
      tickerColorGrowing: "rgba(129, 140, 248, 1)",
      showFloatingTooltip: true,
      showSymbolLogo: true,
      belowLineFillColorFalling: "rgba(255, 74, 104, 0.05)",
      isTransparent: "true",
      whitelabel: "false",
      height: 420,
      width: document.getElementById("chart_area").clientWidth
    });
    document.getElementById("chart_area").appendChild(script);

    return () => {
      if (document.getElementById("chart_area")?.hasChildNodes()) {
        const childElement = document.getElementById("chart_area").firstChild;
        document.getElementById("chart_area").removeChild(childElement);
      }
    };
  }, []);

  return <div id="market-overview-widget" />;
};

export default MarketOverview;
