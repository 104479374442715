import React, { useEffect, useState } from "react";
import { atom_image_ad_item, isImageAdModalShow } from "../atom";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getStores, saveStore, updateStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  uploadBytesResumable
} from "firebase/storage";
import { ref } from "firebase/storage";

const AdImageSet = () => {
  const [ad_image_item, setAdImageItem] = useRecoilState(atom_image_ad_item);
  const [adImageUrl, setAdImageUrl] = useState("");
  const setAdImageModalShow = useSetRecoilState(isImageAdModalShow);
  const [imgStatus, setImgStatus] = useState("");

  async function saveFileData(downloadURL, stat) {
    setImgStatus("Upload Finished!");

    const newRes = {
      ...ad_image_item,
      ad_image: downloadURL,
      claimed: "false"
    };
    updateStore("Client", ad_image_item.id, newRes);
    setAdImageUrl(downloadURL);
    setImgStatus("Success!");
  }

  async function handleUpload(storageRef, stat, file) {
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setImgStatus("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            setImgStatus("Upload is paused");
            break;
          // case "running":
          //   setStatus(stat, "Upload is running");
          //   break;
          default:
            break;
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            saveFileData(downloadURL, stat);
          })
          .catch((error) => console.log(error));
      }
    );
  }

  async function confirmUpload(e) {
    if (!e.target.files[0]) return;
    const storage = getStorage();
    const file = e.target.files[0];
    const storageRef = ref(storage, "ad_image/" + ad_image_item.id);

    // Delete the file
    // deleteObject(storageRef)
    //   .then(() => {
    //     setStatus(stat, "Original File deleted!");
    //     handleUpload(storageRef, stat, file);
    //   })
    //   .catch((error) => {
    //     handleUpload(storageRef, stat, file);
    //   });
    setImgStatus("File Deleting!");
    try {
      await deleteObject(storageRef);
    } catch (e) {}
    await handleUpload(storageRef, ad_image_item.id, file);
  }

  const handleClearImg = async () => {
    const storage = getStorage();
    const storageRef = ref(storage, "ad_image/" + ad_image_item.id);
    setImgStatus("File Deleting!");
    try {
      await deleteObject(storageRef);
      setAdImageUrl("");
      setImgStatus("Deleted!");
    } catch (e) {}
    const newRes = {
      ...ad_image_item,
      ad_image: "",
      claimed: "false"
    };
    updateStore("Client", ad_image_item.id, newRes);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (ad_image_item == null) return;

      try {
        const clientData = await getStores("Client");

        if (!IsEmpty(clientData)) {
          const clientUser = Object.entries(clientData).filter(
            (item) => item[1].address === ad_image_item.address
          );

          if (clientUser.length > 0) {
            setAdImageUrl(clientUser[0][1]?.ad_image);
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="font-sans text-lg font-normal max-sm:text-xs">
        <div>
          <p className="text-black text-xl mb-2">Upload image for ad here</p>
          {!IsEmpty(adImageUrl) ? (
            <button
              className="rounded-md bg-red-600 shadow-md shadow-gray-700 px-6 py-1 text-white hover:bg-red-500 float-right"
              onClick={handleClearImg}
            >
              Remove
            </button>
          ) : null}
          <div>
            <input
              type="file"
              className="w-full rounded-sm border-b-black p-3"
              id="file_reward"
              // value={rewardUrl}
              onChange={(e) => confirmUpload(e)}
            />
          </div>

          <div className="p-3 text-black">{imgStatus}</div>
          {!IsEmpty(adImageUrl) ? (
            <img src={adImageUrl} alt="Ad Image" />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AdImageSet;
