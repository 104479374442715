import React, { useEffect, useState } from "react";
import {
  atom_address,
  atom_gold_trade_item,
  isTradeBalanceModalShow
} from "../atom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getStores, updateStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";
import { FormControlLabel, Switch } from "@mui/material";

const GoldTradeBalance = () => {
  const [gold_trade_item, setGoldTradeItem] =
    useRecoilState(atom_gold_trade_item);
  const [balance, setBalance] = useState("");
  const [withdrawal, setWithdrawal] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const setAdTextModalShow = useSetRecoilState(isTradeBalanceModalShow);
  const address = useRecoilValue(atom_address);
  const [goldWin, setGoldWin] = useState(false);
  const [goldWinLabel, setGoldWinLabel] = useState("Win");

  const handleGoldWinLoss = (event) => {
    setGoldWin(event.target.checked);
  };

  useEffect(() => {
    if (goldWin) setGoldWinLabel("Win");
    else setGoldWinLabel("Loss");
  }, [goldWin]);

  const handleSetBalance = () => {
    if (gold_trade_item === null) {
      toast("Write failed. Missing address");
      return;
    }
    let newBalance =
      balance === "" || balance === undefined || balance === null
        ? 0
        : parseFloat(balance);
    let newTotalWithdrawal =
      totalWithdrawal === "" ||
      totalWithdrawal === undefined ||
      totalWithdrawal === null
        ? 0
        : parseFloat(totalWithdrawal);
    let newWithdrawal =
      withdrawal === "" || withdrawal === undefined || withdrawal === null
        ? 0
        : parseFloat(withdrawal);
    newBalance -= newTotalWithdrawal - newWithdrawal;
    const newRes = {
      ...gold_trade_item,
      trade_balance: newBalance,
      win: goldWin,
      withdrawal: newWithdrawal
    };
    updateStore("Client", gold_trade_item.id, newRes);
    setGoldTradeItem(null);
    setAdTextModalShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (atom_gold_trade_item == null) return;

      try {
        const clientData = await getStores("Client");

        if (!IsEmpty(clientData)) {
          const clientUser = Object.entries(clientData).filter(
            (item) =>
              item[1].address === gold_trade_item.address &&
              item[1].admin === address
          );

          if (clientUser.length > 0) {
            setBalance(clientUser[0][1].trade_balance);
            setWithdrawal(clientUser[0][1].withdrawal);
            setTotalWithdrawal(clientUser[0][1].withdrawal);
            setGoldWin(
              clientUser[0][1].win === null ||
                clientUser[0][1].win === undefined
                ? false
                : clientUser[0][1].win
            );
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="font-sans text-lg font-normal max-sm:text-xs">
        <div>
          <p className="text-black text-xl mb-2">Input Gold Trade Balance</p>
          <textarea
            id="txt_gold_trade_balance"
            name="txt_gold_trade_balance"
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
            rows={1}
            className="w-full rounded-sm border border-black p-3 text-black"
            // placeholder="Input Your ETH Address Here."
          />
          <p className="text-black text-xl mb-2">Set Withdrawal Amount</p>
          <textarea
            id="txt_gold_trade_withdraw"
            name="txt_gold_trade_withdraw"
            value={withdrawal}
            onChange={(e) => setWithdrawal(e.target.value)}
            rows={1}
            className="w-full rounded-sm border border-black p-3 text-black"
            // placeholder="Input Your ETH Address Here."
          />
          <div className="text-center mt-2 text-black">
            <FormControlLabel
              control={
                <Switch
                  checked={goldWin}
                  onChange={handleGoldWinLoss}
                  color="secondary"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={goldWinLabel}
            />
          </div>
          <button
            className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-6 py-2 float-right text-white hover:bg-blue-500"
            onClick={handleSetBalance}
          >
            SET
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoldTradeBalance;
