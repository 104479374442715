import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { atom_commission } from "../atom";
import { IsEmpty } from "../utils/isEmpty";
import { formatCurrency } from "../utils/utilities";
import { West } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CommissionDetails = () => {
  const navigate = useNavigate();
  const commission = useRecoilValue(atom_commission);
  const [detailedItem, setDetailedItem] = useState(null);
  let phoneCommission = "";
  let detailedCommission = "";

  const handleClickBack = () => {
    navigate("/goldtrade_dashboard", { replace: true });
  };
  if (!IsEmpty(commission)) {
    phoneCommission = commission.map((item, index) => {
      return (
        <div className="bg-gray-800 rounded-xl m-1 p-3 font-sans pb-8">
          <div className="flex justify-between items-center mb-4">
            <div>
              {item.direction === "up" ? (
                <span className="text-green-500">Buy:</span>
              ) : (
                <span className="text-red-500">Sell:</span>
              )}
              <span className="text-gray-200">{item.tradeType}/USDT</span>
            </div>
            <div
              className="text-gray-300 text-xs"
              onClick={(e) => setDetailedItem(item)}
            >
              Settled &gt;
            </div>
          </div>
          <div className="flex justify-between text-xs">
            <div className="space-y-2">
              <p className="text-gray-400">Input amount</p>
              <p>{formatCurrency(item.buyVolume)}</p>
            </div>
            <div className="space-y-2">
              <p className="text-gray-400">Opening unit price</p>
              <p>{formatCurrency(item.openPrice)}</p>
            </div>
            <div className="space-y-2">
              <p className="text-gray-400">Open Time</p>
              <p>{item.openTime}</p>
            </div>
          </div>
        </div>
      );
    });
  }

  if (detailedItem !== null) {
    detailedCommission = (
      <div className="font-sans">
        <div className="bg-gray-800 justify-between mb-2 p-3 items-center flex">
          <span
            className="text-gray-500"
            onClick={(e) => setDetailedItem(null)}
          >
            <West fontSize="medium" />
          </span>
          <span className="text-2xl text-green-500 pr-4">Details</span>
        </div>
        <div className="m-2">
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Amount</p>
            <p>{formatCurrency(detailedItem.buyVolume)} USDT</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Duration</p>
            <p>{detailedItem.cycle}s</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Contract type</p>
            {detailedItem.direction === "up" ? (
              <p className="text-green-500">Buy</p>
            ) : (
              <p className="text-red-500">Sell</p>
            )}
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>State</p>
            <p>Settlement succeeded</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Opening unit price</p>
            <p>{formatCurrency(detailedItem.openPrice)} USDT</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Open Time</p>
            <p>{detailedItem.openTime}</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Closing unit price</p>
            <p>{formatCurrency(detailedItem.closingPrice)} USDT</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Closing time</p>
            <p>{detailedItem.closingTime}</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Profit and loss amount</p>
            {detailedItem.profit > 0 ? (
              <p className="text-green-500">
                +{formatCurrency(detailedItem.profit)}
              </p>
            ) : (
              <p className="text-red-500">
                {formatCurrency(detailedItem.profit)}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {detailedItem === null ? (
        <div>
          <div className="bg-gray-800 justify-between mb-2 p-3 items-center flex font-sans">
            <span className="text-gray-500" onClick={handleClickBack}>
              <West fontSize="medium" />
            </span>
            <span className="text-2xl text-green-500 pr-4">
              Historical Commission
            </span>
          </div>
          {phoneCommission}
        </div>
      ) : (
        <div>{detailedCommission}</div>
      )}
    </div>
  );
};

export default CommissionDetails;
