import React, { useState } from "react";
import { atom_address, atom_refresh } from "../atom";
import { useNavigate } from "react-router-dom";
import { getStores, saveStore, updateStore } from "../api/crud";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { IsEmpty } from "../utils/isEmpty";
import { getNewYorkDate } from "../utils/utilities";
import { useRecoilValue } from "recoil";

const SendEthPanel = () => {
  const navigate = useNavigate();
  const [recipient, setRecipient] = useState("");
  const [oneEth, setOneEth] = useState("");
  const [deposit, setDeposit] = useState("");
  const [fee, setFee] = useState("");
  const [nominal, setNominal] = useState("");
  // const [transDate, setTransDate] = useState(
  // moment(Date.now()).format("YYYY-MM-DD hh:mm:ss")
  // );
  const [refresh, setRefresh] = useState(atom_refresh);
  const address = useRecoilValue(atom_address);

  const confirmHandle = async () => {
    const action = "Sent";

    const clientData = await getStores("Client");
    const receiver = Object.entries(clientData).filter(
      (one) =>
        (one[1].email === recipient ||
          one[1].name === recipient ||
          one[1].address === recipient) &&
        one[1].admin === address
    );
    if (IsEmpty(receiver)) {
      toast("No Such Client!");
      return;
    }
    const curDate = await getNewYorkDate();
    let transDate = moment(curDate).format("YYYY-MM-DD HH:mm:ss");
    const data = {
      name: receiver[0][1].name,
      email: receiver[0][1].email,
      address: receiver[0][1].address,
      admin: address,
      oneEth: IsEmpty(oneEth) ? "0" : oneEth,
      deposit: IsEmpty(deposit) ? "0" : deposit,
      fee: IsEmpty(fee) ? "0" : fee,
      nominal: IsEmpty(nominal) ? "0" : nominal,
      date: transDate,
      action: action
    };
    const flag1 = saveStore("Transaction", data);
    let flag2 = true;
    const clientAssetData = await getStores("ClientAsset");
    if (IsEmpty(clientAssetData)) {
      flag2 = saveStore("ClientAsset", {
        ...data,
        usdt: 0,
        withdrawn: 0,
        withdrawable: IsEmpty(nominal) ? "0" : nominal
      });
    } else {
      const clientAsset = Object.entries(clientAssetData).filter(
        (one) =>
          one[1].email === data.email &&
          one[1].address === data.address &&
          one[1].admin === address
      );
      let deposit = parseFloat(data.deposit);
      let nominalValue = parseFloat(data.nominal);
      let usdt = 0;
      if (IsEmpty(clientAsset)) {
        flag2 = saveStore("ClientAsset", {
          ...data,
          usdt: 0,
          withdrawn: 0,
          withdrawable: nominalValue
        });
      } else {
        deposit += parseFloat(clientAsset[0][1].deposit);
        let withdrawable =
          parseFloat(clientAsset[0][1].withdrawable) + nominalValue;
        usdt = clientAsset[0][1].usdt;
        let updateAsset = {
          // name: data.name,
          // email: data.email,
          // address: data.address,
          oneEth: data.oneEth,
          deposit: deposit,
          usdt: usdt,
          fee: IsEmpty(fee) ? clientAsset[0][1].fee : fee,
          // date: data.date,
          action: data.action,
          nominal: nominalValue,
          withdrawable: withdrawable
        };
        flag2 = updateStore("ClientAsset", clientAsset[0][1].id, updateAsset);
      }
    }
    if (flag1 && flag2) {
      toast("Sent successfully!");
      setRefresh(!refresh);
    } else {
      toast("Send failed!");
    }
  };

  const confirmBack = () => {
    navigate("/admin_dashboard", { replace: true });
  };

  return (
    <div className="font-sans text-lg font-normal max-sm:text-xs">
      <div className="m-10">
        <button
          className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-10 py-3 text-white hover:bg-blue-500"
          onClick={confirmBack}
        >
          Go to Dashboard
        </button>
      </div>
      <div className="w-1/2 max-sm:w-3/4 bg-white m-auto mt-20 p-5 rounded-lg">
        <div className="grid grid-rows-3 grid-cols-3 text-black">
          {/* <div className="p-3">Email:</div>
          <div className="col-span-2">
            <input
              type="email"
              id="txt_email"
              name="txt_email"
              className="w-full rounded-sm border-b-black p-3"
              placeholder="Input Email Here."
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            </div>*/}
          <div className="p-3">Recipient:</div>
          <div className="col-span-2">
            <input
              type="text"
              id="txt_address"
              name="txt_address"
              className="w-full rounded-sm border-b-black p-3"
              placeholder="Input Recipient's Name, Email or Wallet Address Here."
              value={recipient}
              onChange={(e) => {
                setRecipient(e.target.value);
              }}
            />
          </div>
          <div className="md:p-3 max-sm:py-3">1 BTC(ETH):</div>
          <div className="col-span-2">
            <input
              type="text"
              id="txt_eth"
              name="txt_eth"
              className="w-full rounded-sm border-b-black p-3"
              placeholder="How much is 1BTC or 1ETH in USD?"
              value={oneEth}
              onChange={(e) => {
                setOneEth(e.target.value);
              }}
            />
          </div>
          <div className="md:p-3 max-sm:py-3">Amount to Send:</div>
          <div className="col-span-2">
            <input
              type="text"
              id="txt_deposit"
              name="txt_deposit"
              className="w-full rounded-sm border-b-black p-3"
              placeholder="Input Amount of BTC to Send Here."
              value={deposit}
              onChange={(e) => {
                setDeposit(e.target.value);
              }}
            />
          </div>
          {/* <div className="pl-3">Amount of Withdrawal:</div>
          <div className="col-span-2">
            <input
              type="text"
              id="txt_withdrawal"
              name="txt_withdrawal"
              className="w-full rounded-sm border-b-black p-3"
              placeholder="Input Withdrawal Amount Here."
              value={withdrawal}
              onChange={(e) => {
                setWithdrawal(e.target.value);
              }}
            />
          </div> */}
          <div className="md:p-3 max-sm:py-3">Fee:</div>
          <div className="col-span-2">
            <input
              type="text"
              id="txt_fee"
              name="txt_fee"
              className="w-full rounded-sm border-b-black p-3"
              placeholder="Input Fee Here."
              value={fee}
              onChange={(e) => {
                setFee(e.target.value);
              }}
            />
          </div>
          <div className="md:p-3 max-sm:py-3">Nominal:</div>
          <div className="col-span-2">
            <input
              type="text"
              id="txt_nominal"
              name="txt_nominal"
              className="w-full rounded-sm border-b-black p-3"
              placeholder="Input Nominal To Send Here."
              value={nominal}
              onChange={(e) => {
                setNominal(e.target.value);
              }}
            />
          </div>
          {/* <div className="md:p-3 max-sm:py-3">Date:</div>
          <div className="col-span-2">
            <input
              type="date"
              id="txt_date"
              name="txt_date"
              className="w-full rounded-sm border-b-black p-3"
              value={transDate}
              onChange={(e) => {
                settransDate(e.target.value);
              }}
            />
          </div> */}
          <div className="col-span-3 text-center mt-10">
            <button
              className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-10 py-3 text-white hover:bg-blue-500"
              onClick={confirmHandle}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SendEthPanel;
