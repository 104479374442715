import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getStores, saveStore } from "../api/crud";
import { atom_address, isCountdownModalShow } from "../atom";
import { IsEmpty } from "../utils/isEmpty";
import { tradingEvaluationTime } from "../utils/constants";
import { formatCurrency } from "../utils/utilities";
import axios from "axios";

export const CountdownPane = ({
  type,
  buyVolume,
  balance,
  direction,
  cycle,
  expected,
  buyPrice
}) => {
  const [seconds, setSeconds] = useState(cycle);
  const [isWinner, setIsWinner] = useState(null);
  const [price, setPrice] = useState(null);
  const setCountdownModal = useSetRecoilState(isCountdownModalShow);
  const address = useRecoilValue(atom_address);
  useEffect(() => {
    const interval = setInterval(async () => {
      // Code to be executed at the defined interval
      setSeconds((seconds) => seconds - 1);
      if (type === "XAUUSD") {
        const requestOptions = {
          method: "GET",
          headers: {
            "x-access-token": process.env.REACT_APP_GOLD_API // Replace with your API token
          }
        };

        fetch("https://www.goldapi.io/api/XAU/USD", requestOptions)
          .then((response) => response.json())
          .then((result) => setPrice(result.price))
          .catch((error) => console.log("error", error));
      } else if (type === "ETH") {
        try {
          const response = await axios.get(
            "https://api.coingecko.com/api/v3/simple/price",
            {
              params: {
                ids: "ethereum",
                vs_currencies: "usd"
              }
            }
          );
          setPrice(response.data.ethereum.usd);
        } catch (err) {}
      } else if (type === "BTC") {
        try {
          const response = await axios.get(
            "https://api.coingecko.com/api/v3/simple/price",
            {
              params: {
                ids: "bitcoin",
                vs_currencies: "usd"
              }
            }
          );
          setPrice(response.data.bitcoin.usd);
        } catch (err) {}
      }
      if (seconds < tradingEvaluationTime) {
        clearInterval(interval);
      }
    }, 1000); // Interval set to 1 second (1000 milliseconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);

  useEffect(() => {
    const getWinLoss = async () => {
      const clientData = await getStores("Client");
      if (!IsEmpty(clientData)) {
        const clientUser = Object.entries(clientData).filter(
          (item) => item[1].address === address
        );
        if (clientUser.length > 0) {
          setIsWinner(clientUser[0][1].win);
        }
      }
    };
    getWinLoss();
  }, []);

  const handleContinue = async () => {
    setCountdownModal(false);
  };

  return (
    <div className="font-sans bg-gray-900 px-6 pt-4">
      {seconds > 0 ? (
        <div className="text-center flex items-center justify-center mb-4">
          <svg height="140" width="140" xmlns="http://www.w3.org/2000/svg">
            <circle
              r="60"
              cx="70"
              cy="70"
              fill="transparent"
              stroke="#f7435c"
              stroke-width="8"
            />
          </svg>
          <span className="absolute text-3xl">{seconds}</span>
        </div>
      ) : seconds > -10 ? (
        <div
          className={clsx(
            "border rounded-lg px-[40px] h-[140px] mb-4 justify-center flex items-center",
            {
              "border-green-600": direction === "up",
              "border-red-500": direction === "fall"
            }
          )}
        >
          Evaluating. Please wait...
        </div>
      ) : (
        <div
          className={clsx(
            "border rounded-lg px-[40px] h-[140px] mb-4 text-center items-center justify-center flex",
            {
              "border-green-600": direction === "up",
              "border-red-500": direction === "fall"
            }
          )}
        >
          {isWinner ? (
            <span className="absolute text-xl  text-green-600 font-bold">
              +{formatCurrency(expected)}
            </span>
          ) : (
            <span className="absolute text-xl  text-red-600 font-bold">
              -{formatCurrency(buyVolume)}
            </span>
          )}
        </div>
      )}
      <div
        className={clsx("border rounded-lg px-[40px]", {
          "border-green-600": direction === "up",
          "border-red-500": direction === "fall"
        })}
      >
        <div className="flex justify-between py-1">
          <div>Price</div>
          <div className="text-sm my-auto">{formatCurrency(price)}</div>
        </div>
        <div className="flex justify-between py-1">
          <div>Cycle</div>
          <div className="text-sm my-auto">{cycle} S</div>
        </div>
        <div className="flex justify-between py-1">
          <div>Type</div>
          <div
            className={clsx("text-sm my-auto", {
              "text-green-500": direction === "up",
              "text-red-500": direction === "fall"
            })}
          >
            {direction === "up" ? "Buy" : "Sell"}
          </div>
        </div>
        <div className="flex justify-between py-1">
          <div>Money</div>
          <div className="text-sm my-auto">{formatCurrency(buyVolume)}</div>
        </div>
        <div className="flex justify-between py-1">
          <div>Buy</div>
          <div className="text-sm my-auto">{formatCurrency(buyPrice)}</div>
        </div>
      </div>
      <div
        className="mt-[25px] bg-green-600  text-center mx-auto text-sm p-3 rounded-lg font-bold cursor-pointer"
        onClick={handleContinue}
      >
        Continue
      </div>
      <div className="h-[50px]"></div>
    </div>
  );
};
