import axios from "axios";

export const subtractDates = (date1, date2) => {
  let ret = Math.floor(
    (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)
  );
  return ret > 0 ? ret : 0;
};

export const percentPaid = (money) => {
  if (money >= 50 && money < 10000) return 0.02;
  else if (money < 50000) return 0.03;
  else if (money < 100000) return 0.04;
  else return 0.045;
};

export const getNewYorkDate = async () => {
  try {
    const res = await axios.get(
      "https://api.timezonedb.com/v2.1/get-time-zone?key=XDFSGSXEUEL0&format=json&by=zone&zone=America/New_York"
    );
    const newYorkDate = new Date(res.data.formatted);
    return newYorkDate;
  } catch (err) {
    console.log(err);
  }
};

export const convertToNewYorkSeven = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  let day = date.getDate();
  const hour = date.getHours();

  let convertedDate;
  if (hour < 19) {
    day--;
    convertedDate = new Date(year, month, day, 19, 0, 0);
  } else {
    convertedDate = new Date(year, month, day, 19, 0, 0);
  }
  return convertedDate;
};

export const getDateTimeAheadOfSeconds = (curTime, sec) => {
  const future = new Date(curTime.getTime() + sec * 1000);

  // Get the future date and time components
  const year = future.getFullYear();
  const month = String(future.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(future.getDate()).padStart(2, "0");
  const hours = String(future.getHours()).padStart(2, "0");
  const minutes = String(future.getMinutes()).padStart(2, "0");
  const seconds = String(future.getSeconds()).padStart(2, "0");

  // Format the date and time as a string
  const formattedFutureDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedFutureDateTime;
};

export const calculateSecondsBetween = (dateTime1, dateTime2) => {
  // Parse the datetime strings into Date objects
  const date1 = new Date(dateTime1);
  const date2 = new Date(dateTime2);

  // Calculate the difference in milliseconds
  const diffInMilliseconds = date2 - date1;

  // Convert the difference from milliseconds to seconds
  const diffInSeconds = diffInMilliseconds / 1000;

  return diffInSeconds;
};

export function formatCurrency(value, locale = "en-US", currency = "USD") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency
  }).format(value);
}

export const reduceString = (str) => {
  let ret = "";
  if (str.length > 20) {
    ret = str.substring(0, 6) + "...." + str.slice(-4);
  } else {
    ret = str;
  }
  return ret;
};
