import React, { useState } from "react";
import TradingViewWidget from "../components/TradingViewWidget";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const periods = [
  { second: "30", percent: "25" },
  { second: "60", percent: "35" },
  { second: "90", percent: "45" },
  { second: "120", percent: "60" },
  { second: "180", percent: "70" },
  { second: "360", percent: "80" }
];
const amounts = [
  { value: 10 },
  { value: 5000 },
  { value: 20000 },
  { value: 50000 },
  { value: 100000 },
  { value: 200000 }
];

const GoldAdminDashboard = () => {
  const navigate = useNavigate();

  const [periodSecond, setPeriodSecond] = useState(0);
  const [buyVolume, setBuyVolume] = useState("");
  const handleClickClientList = () => {
    navigate("/goldclient_list", { replace: true });
  };
  return (
    <div className=" bg-gray-900 h-screen font-sans">
      <div className="grid grid-rows-5 grid-cols-7 gap-4">
        <div className="col-span-5 row-span-4">
          <TradingViewWidget />
        </div>
        <div className="bg-black/20 row-span-4 col-span-2 px-6 border border-gray-700">
          <button
            className=" absolute right-6 mt-[30px] px-6 py-2 bg-purple-600 rounded-md hover:bg-purple-500"
            onClick={handleClickClientList}
          >
            Client List
          </button>
          <div className="mt-[100px] mb-4 text-gray-400">Selection Period</div>
          <div className="grid grid-cols-4 gap-4">
            {periods.map((period) => (
              <div
                className={clsx({
                  "bg-red-500": period.second === periodSecond,
                  "bg-gray-800 p-3 text-center pb-8 rounded-lg cursor-pointer": true
                })}
                onClick={(e) => {
                  setPeriodSecond(period.second);
                }}
              >
                <div>{period.second}S</div>
                <div>{period.percent}%</div>
              </div>
            ))}
          </div>
          <div className="my-4 text-gray-400">Buy Volume</div>
          <input
            type="text"
            className="w-full bg-gray-800 p-2 pl-4 text-sm text-gray-200 placeholder:text-gray-400"
            placeholder="Please enter amount"
            value={buyVolume}
            onChange={(e) => {
              setBuyVolume(e.target.value);
            }}
          />
          <div className="mt-8 grid grid-cols-6 gap-1">
            {amounts.map((amount) => (
              <div
                className={clsx({
                  "bg-red-500": buyVolume === amount.value,
                  "bg-gray-800 px-4 py-3 text-center rounded-md cursor-pointer": true
                })}
                onClick={(e) => {
                  setBuyVolume(amount.value);
                }}
              >
                <div className="text-xs">${amount.value}</div>
              </div>
            ))}
          </div>
          <div className="mt-[100px] mb-4 text-gray-400">Balance: --USDT</div>
          <div className="mt-[50px] flex justify-between gap-10 text-sm text-gray-100">
            <button className="w-full bg-green-500 rounded-lg p-3">
              Buy up
            </button>
            <button className="w-full bg-red-500 rounded-lg p-3">
              Buy fall
            </button>
          </div>
        </div>
        <div className="bg-black/20 col-span-7 border border-gray-700"></div>
      </div>
    </div>
  );
};

export default GoldAdminDashboard;
