import { atom_address, atom_write_ad_item, isTextAdModalShow } from "../atom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getStores, updateStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";
import { useEffect, useState } from "react";

const AdTextWrite = () => {
  const [ad_write_item, setAdWriteItem] = useRecoilState(atom_write_ad_item);
  const [adText, setAdText] = useState("");
  const setAdTextModalShow = useSetRecoilState(isTextAdModalShow);
  const address = useRecoilValue(atom_address);

  const handleWrite = () => {
    if (ad_write_item === null) {
      toast("Write failed. Missing address");
      return;
    }
    const newRes = { ...ad_write_item, ad_text: adText };
    updateStore("Client", ad_write_item.id, newRes);
    setAdWriteItem(null);
    setAdTextModalShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (ad_write_item == null) return;

      try {
        const clientData = await getStores("Client");

        if (!IsEmpty(clientData)) {
          const clientUser = Object.entries(clientData).filter(
            (item) =>
              item[1].address === ad_write_item.address &&
              item[1].admin === address
          );

          if (clientUser.length > 0) {
            setAdText(clientUser[0][1]?.ad_text);
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="font-sans text-lg font-normal max-sm:text-xs">
        <div>
          <p className="text-black text-xl mb-2">Write ad text here</p>
          <textarea
            id="txt_adwrite"
            name="txt_adwrite"
            value={adText}
            onChange={(e) => setAdText(e.target.value)}
            rows={5}
            className="w-full rounded-sm border border-black p-3 text-black"
            // placeholder="Input Your ETH Address Here."
          />
          <button
            className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-6 py-2 float-right text-white hover:bg-blue-500"
            onClick={handleWrite}
          >
            Write
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdTextWrite;
