import React, { useEffect } from "react";

const TradingViewWidget = ({ type }) => {
  useEffect(() => {
    const symbolType = type?.substring(0, 3) + "USD";
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/tv.js";
    script.async = true;
    script.onload = () => {
      new window.TradingView.widget({
        autosize: true,
        symbol: symbolType,
        interval: "D",
        timezone: "Europe/Zurich",
        theme: "Dark",
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        hide_side_toolbar: true,
        allow_symbol_change: false,
        studies: ["RSI@tv-basicstudies", "StochasticRSI@tv-basicstudies"],
        container_id: "tradingview_74048"
      });
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [type]);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_74048" className="h-[74vh]"></div>
    </div>
  );
};

export default TradingViewWidget;
