import React, { useEffect, useState } from "react";
import TradingViewWidget from "../components/TradingViewWidget";
import clsx from "clsx";
import { getStores, updateStore } from "../api/crud";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  atom_address,
  atom_commission,
  atom_goldwithdrawal,
  atom_refresh,
  atom_tradeType,
  isBuySellModalShow,
  isCountdownModalShow,
  isGoldToMiningWithdrawShow,
  isWithdrawShow
} from "../atom";
import { IsEmpty } from "../utils/isEmpty";
import { BuySellPane } from "../components/BuySellPane";
import BuySellModal from "../components/BuySellModal";
import { ToastContainer, toast } from "react-toastify";
import { CountdownPane } from "../components/CountdownPane";
import { calculateSecondsBetween, formatCurrency } from "../utils/utilities";
import moment from "moment";
import { tradingEvaluationTime } from "../utils/constants";
import Modal from "../components/Modal";
import GoldWithdraw from "../components/GoldWithdraw";
import GoldToMiningWithdraw from "../components/GoldToMiningWithdraw";
import AdvertiseModal from "../components/AdvertiseModal";
import DepositAdvertisePane from "../components/DepositAdvertisePane";
import { useNavigate } from "react-router-dom";
import { West } from "@mui/icons-material";

const periods = [
  { second: "10", percent: "5" },
  { second: "15", percent: "10" },
  { second: "30", percent: "25" },
  { second: "60", percent: "35" },
  { second: "90", percent: "45" },
  { second: "120", percent: "60" },
  { second: "180", percent: "70" },
  { second: "360", percent: "80" }
];
const amounts = [
  { value: 10 },
  { value: 5000 },
  { value: 20000 },
  { value: 50000 },
  { value: 100000 },
  { value: 200000 }
];

const mediaQuery = window.matchMedia("(max-width: 768px)");

const GoldTradeDashboard = () => {
  const navigate = useNavigate();
  const [period, setPeriod] = useState({});
  const [buyVolume, setBuyVolume] = useState("");
  const address = useRecoilValue(atom_address);
  const [balance, setBalance] = useState("--USDT");
  const [buySellModal, setBuySellModal] = useRecoilState(isBuySellModalShow);
  const [countdownModal, setCountdownModal] =
    useRecoilState(isCountdownModalShow);
  const [tradeType, setTradeType] = useRecoilState(atom_tradeType);
  const [direction, setDirection] = useState("up");
  const [expected, setExpected] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [mandates, setMandates] = useState(null);
  const [commission, setCommission] = useRecoilState(atom_commission);
  const [curTime, setCurTime] = useState("");
  const [isWinner, setIsWinner] = useState(null);
  const [withdrawModal, setWithdrawModal] = useRecoilState(isWithdrawShow);
  const [goldToMiningWithdrawModal, setGoldToMiningWithdrawModal] =
    useRecoilState(isGoldToMiningWithdrawShow);
  const [withdrawal, setWithdrawal] = useRecoilState(atom_goldwithdrawal);
  const [advertiseModal, setAdvertiseModal] = useState(false);
  const [refresh, setRefresh] = useRecoilState(atom_refresh);
  const [detailedItem, setDetailedItem] = useState(null);
  let currentMandate = "";
  let historicalCommission = "";
  let phoneCommission = "";
  let detailedCommission = "";

  const setClientBalance = async () => {
    const tblData = await getStores("Client");
    if (tblData === null) {
      return;
    }
    const items = Object.entries(tblData).filter(
      (item) => item[1].address === address
    );
    if (!IsEmpty(items)) {
      setBalance(items[0][1].trade_balance);
      setIsWinner(items[0][1].win);
      setWithdrawal(
        items[0][1].withdrawal === "" ||
          items[0][1].withdrawal === undefined ||
          items[0][1].withdrawal === null
          ? 0
          : items[0][1].withdrawal
      );
    }
  };

  useEffect(() => {
    if (tabIndex === 1) {
      const setCommissionData = async () => {
        const tblData = await getStores("GoldTransaction");
        if (tblData === null) {
          return;
        }
        const items = Object.entries(tblData)
          .filter(
            (item) => item[1].address === address && item[1].tabIndex === 1
          )
          .map((item) => item[1])
          .sort((a, b) => b.id - a.id);
        if (!IsEmpty(items)) {
          setCommission(items);
        }
      };
      setCommissionData();
    }
  }, [tabIndex]);

  useEffect(() => {
    setClientBalance();
  }, [refresh]);

  const checkPossibility = () => {
    if (buyVolume === "" || IsEmpty(period)) {
      toast("Please select period and volume.");
      return false;
    }
    if (balance < buyVolume) {
      toast("Buy volume should be equal or less than your balance.");
      return false;
    }
    if (period.percent === "35" && buyVolume < 5000) {
      toast("Can not be less than the minimum investment limit:$5000");
      return false;
    }
    if (period.percent === "45" && buyVolume < 20000) {
      toast("Can not be less than the minimum investment limit:$20000");
      return false;
    }
    if (period.percent === "60" && buyVolume < 50000) {
      toast("Can not be less than the minimum investment limit:$50000");
      return false;
    }
    if (period.percent === "70" && buyVolume < 100000) {
      toast("Can not be less than the minimum investment limit:$100000");
      return false;
    }
    if (period.percent === "80" && buyVolume < 200000) {
      toast("Can not be less than the minimum investment limit:$200000");
      return false;
    }
    return true;
  };

  const updateBalance = async () => {
    const tblData = await getStores("Client");
    if (tblData === null) {
      return;
    }
    const items = Object.entries(tblData).filter(
      (item) => item[1].address === address
    );
    if (!IsEmpty(items)) {
      let newBalance = items[0][1].trade_balance;
      if (isWinner) {
        newBalance += parseFloat(expected);
      } else {
        newBalance -= parseFloat(buyVolume);
      }
      const newRes = { ...items[0][1], trade_balance: newBalance };
      await updateStore("Client", items[0][1].id, newRes);
      setBalance(newBalance);
    }
  };

  useEffect(() => {
    if (!countdownModal) {
      const setCurrentMandate = async () => {
        const tblData = await getStores("GoldTransaction");
        if (tblData === null) {
          return;
        }
        const items = Object.entries(tblData)
          .filter(
            (item) => item[1].address === address && item[1].tabIndex === -1
          )
          .map((item) => item[1])
          .sort((a, b) => b.id - a.id);
        if (!IsEmpty(items)) {
          setMandates(items);
        }
      };
      setCurrentMandate();
    }
  }, [countdownModal]);

  const handleBuyUp = () => {
    setClientBalance();
    if (!checkPossibility()) return;
    setDirection("up");
    let exp = (parseFloat(buyVolume) * period.percent) / 100;
    exp = Math.round(exp * 100) / 100;
    setExpected(exp);
    setBuySellModal(true);
  };
  const handleBuyFall = () => {
    setClientBalance();
    if (!checkPossibility()) return;
    setDirection("fall");
    let exp = (parseFloat(buyVolume) * period.percent) / 100;
    exp = Math.round(exp * 100) / 100;
    setExpected(exp);
    setBuySellModal(true);
  };

  const handleDeposit = () => {
    setAdvertiseModal(true);
  };

  const handleWithdraw = () => {
    setWithdrawModal(true);
  };

  const handleWithdrawToMining = () => {
    setGoldToMiningWithdrawModal(true);
  };

  const handleToMining = () => {
    navigate("/client_dashboard", { replace: true });
  };

  const handleClickDetails = () => {
    navigate("/commission_details", { replace: true });
  };

  const handleClickGoldTrade = () => {
    setTradeType("XAUUSD");
  };
  const handleClickBTCTrade = () => {
    setTradeType("BTC");
  };
  const handleClickETHTrade = () => {
    setTradeType("ETH");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const curTime = new Date();
      let strTime = moment(curTime).format("YYYY-MM-DD HH:mm:ss");
      setCurTime(strTime);
      setClientBalance();
    }, 30000); // Interval set to 1 second (1000 milliseconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);

  if (!IsEmpty(mandates)) {
    currentMandate = mandates
      .filter((one) => one.tabIndex === -1)
      .map((item) => {
        let dt1 = item.openTime;
        let seconds = calculateSecondsBetween(dt1, curTime);
        seconds = parseInt(item.cycle) - seconds;
        if (seconds < tradingEvaluationTime) {
          const deviation = 0.01 + Math.random() * 1;
          let closingPrice = item.openPrice;
          if (item.direction === "up") {
            if (isWinner) closingPrice += deviation;
            else closingPrice -= deviation;
          } else {
            if (isWinner) closingPrice -= deviation;
            else closingPrice += deviation;
          }
          const newRes = {
            ...item,
            tabIndex: 1,
            closingPrice: closingPrice,
            profit: isWinner ? expected : -1 * parseFloat(buyVolume)
          };
          item.tabIndex = 1;
          updateBalance();
          updateStore("GoldTransaction", item.id, newRes);
        }
        return (
          <tr className="bg-gray-900 border-b border-b-gray-800 text-sm max-sm:text-xs">
            <td className="py-2 px-1">{item.tradeType}</td>
            <td className="px-1">
              {item.direction === "up" ? (
                <span className="text-green-500">Buy</span>
              ) : (
                <span className="text-red-500">Sell</span>
              )}
            </td>
            <td className="px-1">To be settled</td>
            <td className="px-1">{formatCurrency(item.buyVolume)}</td>
            <td className="px-1">{formatCurrency(item.openPrice)}</td>
            <td className="px-1 whitespace-nowrap">{item.openTime}</td>
            <td className="px-1">{seconds > 0 ? seconds : "0"}</td>
          </tr>
        );
      });
  }

  if (!IsEmpty(commission)) {
    phoneCommission = commission.map((item, index) => {
      if (index < 5) {
        return (
          <div className="bg-gray-800 rounded-xl m-1 p-3 font-sans pb-8">
            <div className="flex justify-between items-center mb-4">
              <div>
                {item.direction === "up" ? (
                  <span className="text-green-500">Buy:</span>
                ) : (
                  <span className="text-red-500">Sell:</span>
                )}
                <span className="text-gray-200">
                  {item.tradeType?.substring(0, 3)}/USDT
                </span>
              </div>
              <div
                className="text-gray-300 text-xs"
                onClick={(e) => setDetailedItem(item)}
              >
                Settled &gt;
              </div>
            </div>
            <div className="flex justify-between text-xs">
              <div className="space-y-2">
                <p className="text-gray-400">Input amount</p>
                <p>{formatCurrency(item.buyVolume)}</p>
              </div>
              <div className="space-y-2">
                <p className="text-gray-400">Opening unit price</p>
                <p>{formatCurrency(item.openPrice)}</p>
              </div>
              <div className="space-y-2">
                <p className="text-gray-400">Open Time</p>
                <p>{item.openTime}</p>
              </div>
            </div>
          </div>
        );
      }
    });
    historicalCommission = commission.map((item) => {
      return (
        <tr className="bg-gray-900 border-b border-b-gray-800 text-sm max-sm:text-xs">
          <td className="py-2 px-1">{item.tradeType}</td>
          <td>
            {item.direction === "up" ? (
              <span className="text-green-500">Buy</span>
            ) : (
              <span className="text-red-500">Sell</span>
            )}
          </td>
          <td className="px-1">Settled</td>
          <td className="px-1">{formatCurrency(item.buyVolume)}</td>
          <td className="px-1">{formatCurrency(item.openPrice)}</td>
          <td className="px-1">{formatCurrency(item.closingPrice)}</td>
          <td className="px-1 whitespace-nowrap">{item.openTime}</td>
          <td className="px-1 whitespace-nowrap">{item.closingTime}</td>
          <td className="px-1">
            {item.profit > 0 ? (
              <span className="text-green-500">
                {formatCurrency(item.profit)}
              </span>
            ) : (
              <span className="text-red-500">
                {formatCurrency(item.profit)}
              </span>
            )}
          </td>
        </tr>
      );
    });
  }

  if (detailedItem !== null) {
    detailedCommission = (
      <div>
        <div className="bg-gray-800 justify-between mb-2 p-3 items-center flex">
          <span
            className="text-gray-500"
            onClick={(e) => setDetailedItem(null)}
          >
            <West fontSize="medium" />
          </span>
          <span className="text-2xl text-green-500 pr-4">Details</span>
        </div>
        <div className="m-2">
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Amount</p>
            <p>{formatCurrency(detailedItem.buyVolume)} USDT</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Duration</p>
            <p>{detailedItem.cycle}s</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Contract type</p>
            {detailedItem.direction === "up" ? (
              <p className="text-green-500">Buy</p>
            ) : (
              <p className="text-red-500">Sell</p>
            )}
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>State</p>
            <p>Settlement succeeded</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Opening unit price</p>
            <p>{formatCurrency(detailedItem.openPrice)} USDT</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Open Time</p>
            <p>{detailedItem.openTime}</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Closing unit price</p>
            <p>{formatCurrency(detailedItem.closingPrice)} USDT</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Closing time</p>
            <p>{detailedItem.closingTime}</p>
          </div>
          <div className="flex justify-between text-sm bg-gray-800 rounded-lg m-2 px-3 py-4">
            <p>Profit and loss amount</p>
            {detailedItem.profit > 0 ? (
              <p className="text-green-500">
                +{formatCurrency(detailedItem.profit)}
              </p>
            ) : (
              <p className="text-red-500">
                {formatCurrency(detailedItem.profit)}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className=" bg-gray-900 h-full font-sans">
      <div className="sm:grid sm:grid-rows-5 sm:grid-cols-7 sm:gap-4">
        <div className="sm:col-span-5 sm:row-span-4">
          <button
            className="px-8 py-3 rounded-md shadow-md bg-green-500 m-2 text-white hover:bg-green-600"
            onClick={handleClickGoldTrade}
          >
            GOLD TRADE
          </button>
          <button
            className="px-8 py-3 rounded-md shadow-md bg-red-500 m-2 text-white hover:bg-red-600"
            onClick={handleClickBTCTrade}
          >
            BTC TRADE
          </button>
          <button
            className="px-8 py-3 rounded-md shadow-md bg-blue-500 m-2 text-white hover:bg-blue-600"
            onClick={handleClickETHTrade}
          >
            ETH TRADE
          </button>
          <TradingViewWidget type={tradeType} />
        </div>
        <div className="bg-black/20 sm:row-span-4 sm:col-span-2 sm:px-6 px-3 border border-gray-700">
          <div className="sm:mt-[35px] mt-[20px] mb-4 text-gray-400 text-right">
            <button
              className=" bg-teal-500 text-white rounded-lg px-4 py-2"
              onClick={handleToMining}
            >
              To Mining
            </button>
          </div>
          <div className="sm:mt-[30px] mt-[20px] mb-4 text-gray-400">
            Selection Period
          </div>
          <div className="flex flex-wrap gap-4">
            {periods.map((prd) => (
              <div
                className={clsx({
                  "bg-red-500": prd.second === period.second,
                  "bg-gray-800 p-3 text-center pb-8 rounded-lg cursor-pointer min-w-[100px]": true
                })}
                onClick={(e) => {
                  setPeriod(prd);
                }}
              >
                <div>{prd.second}S</div>
                <div>{prd.percent}%</div>
              </div>
            ))}
          </div>
          <div className="my-4 text-gray-400">Buy Volume</div>
          <input
            type="text"
            className="w-full bg-gray-800 p-2 pl-4 text-sm text-gray-200 placeholder:text-gray-400"
            placeholder="Please enter amount"
            value={buyVolume}
            onChange={(e) => {
              setBuyVolume(e.target.value);
            }}
          />
          <div className="mt-8 flex flex-wrap gap-1">
            {amounts.map((amount) => (
              <div
                className={clsx({
                  "bg-red-500": buyVolume === amount.value,
                  "bg-gray-800 px-4 py-3 text-center rounded-md cursor-pointer min-w-[70px]": true
                })}
                onClick={(e) => {
                  setBuyVolume(amount.value);
                }}
              >
                <div className="text-xs">${amount.value}</div>
              </div>
            ))}
          </div>
          <div className="sm:mt-[50px] mt-[30px] mb-2 text-gray-400 flex justify-between items-center">
            <div>Balance: {formatCurrency(balance)}</div>
            <button
              className="mr-2 bg-orange-500 text-white rounded-lg p-2"
              onClick={handleWithdrawToMining}
            >
              Withdraw To Mining
            </button>
          </div>
          <div
            className={clsx({
              "pr-2 items-center space-x-4": true,
              " invisible": withdrawal === 0
            })}
          >
            <span>Withdrawal Amount: {formatCurrency(withdrawal)}</span>
            <span className="px-4 py-1 bg-purple-500/30 rounded-lg text-sm">
              In progress
            </span>
          </div>
          <div className="sm:mt-[30px] mt-[20px] mb-[20px] flex justify-between gap-10 text-sm text-gray-100">
            <button
              className="w-full bg-green-500 rounded-lg p-3"
              onClick={handleBuyUp}
            >
              Buy
            </button>
            <button
              className="w-full bg-red-500 rounded-lg p-3"
              onClick={handleBuyFall}
            >
              Sell
            </button>
          </div>
          <div className="sm:mt-[20px] mt-[10px] mb-[20px] flex justify-between gap-10 text-sm text-gray-100">
            <button
              className="w-full bg-blue-500 rounded-lg p-3"
              onClick={handleDeposit}
            >
              Deposit
            </button>
            <button
              className="w-full bg-purple-500 rounded-lg p-3"
              onClick={handleWithdraw}
            >
              Withdraw
            </button>
          </div>
        </div>
        <div className="bg-black/20 col-span-7 border border-gray-700">
          <div className="w-full h-[45px] bg-gray-900 border-b border-b-gray-800">
            <button
              className={clsx({
                "py-2.5 px-4": true,
                "text-green-500 font-semibold bg-gray-800": tabIndex === 0,
                "text-gray-500": tabIndex === 1
              })}
              onClick={() => {
                setTabIndex(0);
              }}
            >
              Current Mandate
            </button>
            <button
              className={clsx({
                "py-2.5 px-4 ": true,
                "text-green-500 font-semibold bg-gray-800": tabIndex === 1,
                "text-gray-500": tabIndex === 0
              })}
              onClick={() => {
                setTabIndex(1);
              }}
            >
              Historical Commission
            </button>
          </div>
          {mediaQuery.matches ? (
            tabIndex === 0 ? (
              currentMandate === "" || currentMandate.length === 0 ? null : (
                <table className="w-full">
                  <thead className="border-b border-b-gray-800 text-center text-gray-500 max-sm:text-xs">
                    <tr>
                      <td className="py-2.5">Symbol</td>
                      <td>Direction</td>
                      <td>State</td>
                      <td>Entrusted amount</td>
                      <td>Opening unit price</td>
                      <td>Open Time</td>
                      <td>Countdown</td>
                    </tr>
                  </thead>
                  <tbody className="text-center">{currentMandate}</tbody>
                </table>
              )
            ) : detailedItem === null ? (
              <div>
                <div className="m-2 flex justify-end">
                  <div className=" cursor-pointer" onClick={handleClickDetails}>
                    <svg
                      t="1655970093813"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="6292"
                      width="18"
                      height="18"
                    >
                      <path
                        d="M170.666667 213.333333h682.666666v85.333334H170.666667V213.333333z m0 512h426.666666v85.333334H170.666667v-85.333334z m0-256h682.666666v85.333334H170.666667v-85.333334z"
                        fill="#e6e6e6"
                        p-id="6293"
                      ></path>
                    </svg>
                  </div>
                </div>
                {phoneCommission}
              </div>
            ) : (
              <div>{detailedCommission}</div>
            )
          ) : (
            <div className="h-[120px] overflow-y-scroll">
              {tabIndex === 0 ? (
                currentMandate === "" || currentMandate.length === 0 ? null : (
                  <table className="w-full">
                    <thead className="border-b border-b-gray-800 text-center text-gray-500 max-sm:text-xs">
                      <tr>
                        <td className="py-2.5">Symbol</td>
                        <td>Direction</td>
                        <td>State</td>
                        <td>Entrusted amount</td>
                        <td>Opening unit price</td>
                        <td>Open Time</td>
                        <td>Countdown</td>
                      </tr>
                    </thead>
                    <tbody className="text-center">{currentMandate}</tbody>
                  </table>
                )
              ) : historicalCommission === "" ||
                historicalCommission.length === 0 ? null : (
                <table className="w-full">
                  <thead className="border-b border-b-gray-800 text-center text-gray-500 max-sm:text-xs">
                    <tr>
                      <td className="py-2.5">Symbol</td>
                      <td>Direction</td>
                      <td>State</td>
                      <td>Entrusted amount</td>
                      <td>Trading limit</td>
                      <td>Transaction unit price</td>
                      <td>Open Time</td>
                      <td>Closing Time</td>
                      <td>Profit</td>
                    </tr>
                  </thead>
                  <tbody className="text-center">{historicalCommission}</tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
      <BuySellModal
        direction={direction}
        openModal={buySellModal}
        setOpenModal={setBuySellModal}
        children={
          <BuySellPane
            type={tradeType}
            balance={balance}
            direction={direction}
            volume={buyVolume}
            cycle={period.second}
            expected={expected}
          />
        }
        showClose={true}
      />
      <BuySellModal
        direction={direction}
        openModal={countdownModal}
        setOpenModal={setCountdownModal}
        children={
          <CountdownPane
            type={tradeType}
            buyVolume={buyVolume}
            balance={balance}
            direction={direction}
            cycle={period.second}
            expected={expected}
            buyPrice={expected}
          />
        }
        showClose={true}
      />
      <Modal
        openModal={withdrawModal}
        setOpenModal={setWithdrawModal}
        children={<GoldWithdraw balance={balance} />}
        showClose={false}
      />
      <Modal
        openModal={goldToMiningWithdrawModal}
        setOpenModal={setGoldToMiningWithdrawModal}
        children={<GoldToMiningWithdraw balance={balance} />}
        showClose={false}
      />
      <AdvertiseModal
        openModal={advertiseModal}
        setOpenModal={setAdvertiseModal}
        children={<DepositAdvertisePane />}
        showClose={false}
      />
    </div>
  );
};

export default GoldTradeDashboard;
