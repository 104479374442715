import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import {
  atom_btcAddress,
  atom_depositText,
  atom_ethAddress,
  atom_trcAddress
} from "../atom";
import { useRecoilValue } from "recoil";
import etherImg from "../assets/img/ethereum.png";
import trcImg from "../assets/img/trc.png";
import btcImg from "../assets/img/bitcoin.png";
import usdtImg from "../assets/img/tether.png";

const DepositAdvertisePane = () => {
  const ethAddr = useRecoilValue(atom_ethAddress);
  const trcAddr = useRecoilValue(atom_trcAddress);
  const btcAddr = useRecoilValue(atom_btcAddress);
  const depositText = useRecoilValue(atom_depositText);
  const handleCopy = (txtToCopy) => {
    copy(txtToCopy);
    toast("Copied");
  };
  return (
    <div className="font-sans bg-deposit-pattern bg-cover bg-opacity-10">
      <div className="font-sans text-lg font-normal max-sm:text-xs text-white sm:w-[600px] sm:h-[500px] w-[300px] h-[400px] overflow-y-auto sm:px-3 px-2 py-5 break-all bg-black/60">
        <div className=" text-white text-center sm:text-base space-y-1 text-xm9 mt-[10px]">
          <div className="font-bold">Transfer Address</div>
          <div>
            <span className="flex items-center justify-center">
              <img src={etherImg} width="20px" alt="ethereum" />
              <img
                src={usdtImg}
                width="20px"
                alt="ethereum"
                className="mr-1"
              />{" "}
              {ethAddr}
              <ContentCopyIcon
                className="text-gray-100 cursor-pointer p-0.5 hover:text-gray-200"
                onClick={() => handleCopy(ethAddr)}
              />
            </span>
          </div>
          <div className="space-x-1">
            <span className="flex items-center justify-center">
              <img src={btcImg} width="20px" alt="bitcoin" className="mr-1" />{" "}
              {btcAddr}
              <ContentCopyIcon
                className="text-gray-100 cursor-pointer p-0.5 hover:text-gray-200"
                onClick={() => handleCopy(btcAddr)}
              />
            </span>
          </div>
          <div>
            <span className="flex items-center justify-center">
              <img src={trcImg} width="20px" alt="ethereum" className="mr-1" />{" "}
              {trcAddr}
              <ContentCopyIcon
                className="text-gray-100 cursor-pointer p-0.5 hover:text-gray-200"
                onClick={() => handleCopy(trcAddr)}
              />
            </span>
          </div>
        </div>
        <pre className=" whitespace-pre-wrap break-words sm:mt-[50px] mt-[30px]">
          {depositText}
        </pre>
      </div>
    </div>
  );
};

export default DepositAdvertisePane;
