import moment from "moment/moment";
import { atom, selector } from "recoil";

export const isLoginShow = atom({
  key: "isLoginShow",
  default: false
});

export const isWithdrawShow = atom({
  key: "isWithdrawShow",
  default: false
});

export const isMiningToGoldWithdrawShow = atom({
  key: "isMiningToGoldWithdrawShow",
  default: false
});

export const isGoldToMiningWithdrawShow = atom({
  key: "isGoldToMiningWithdrawShow",
  default: false
});

export const isClientAddShow = atom({
  key: "isClientAddShow",
  default: false
});

export const isImageAdModalShow = atom({
  key: "isImageAdModalShow",
  default: false
});

export const isTextAdModalShow = atom({
  key: "isTextAdModalShow",
  default: false
});

export const isTradeBalanceModalShow = atom({
  key: "isTradeBalanceModalShow",
  default: false
});

export const isImagePopupShow = atom({
  key: "isImagePopupShow",
  default: true
});

export const isTextPopupShow = atom({
  key: "isTextPopupShow",
  default: true
});

export const isTextLockedModalShow = atom({
  key: "isTextLockedModalShow",
  default: false
});

export const isDepositAdvertiseModalShow = atom({
  key: "isDepositAdvertiseModalShow",
  default: false
});

export const isBuySellModalShow = atom({
  key: "isBuySellModalShow",
  default: false
});

export const isCountdownModalShow = atom({
  key: "isCountdownModalShow",
  default: false
});

export const atom_email = atom({
  key: "atom_email",
  default: ""
});

export const atom_address = atom({
  key: "atom_address",
  default: ""
});

export const atom_oneEth = atom({
  key: "atom_oneEth",
  default: 0.0
});

export const atom_deposit = atom({
  key: "atom_deposit",
  default: 0.0
});

export const atom_withdrawal = atom({
  key: "atom_withdrawal",
  default: 0.0
});

export const atom_goldwithdrawal = atom({
  key: "atom_goldwithdrawal",
  default: 0.0
});

export const atom_fee = atom({
  key: "atom_fee",
  default: 0.0
});

export const atom_transDate = atom({
  key: "atom_transDate",
  default: moment(Date.now()).format("YYYY-MM-DD")
});

export const atom_usdt = selector({
  key: "atom_usdt",
  get: ({ get }) => {
    const oneEth = get(atom_oneEth);
    const deposit = get(atom_deposit);
    return oneEth * deposit;
  }
});

export const atom_commission = atom({
  key: "atom_commission",
  default: null
});

export const atom_refresh = atom({
  key: "atom_refresh",
  default: false
});

export const atom_image_ad_item = atom({
  key: "atom_image_ad_item",
  default: null
});

export const atom_write_ad_item = atom({
  key: "atom_write_ad_item",
  default: null
});

export const atom_write_locked_item = atom({
  key: "atom_write_locked_item",
  default: null
});

export const atom_gold_trade_item = atom({
  key: "atom_gold_trade_item",
  default: null
});

export const atom_tg_text = atom({
  key: "atom_tg_text",
  default: null
});

export const atom_ethAddress = atom({
  key: "atom_ethAddress",
  default: null
});

export const atom_trcAddress = atom({
  key: "atom_trcAddress",
  default: null
});

export const atom_btcAddress = atom({
  key: "atom_btcAddress",
  default: null
});

export const atom_whatsappAddress = atom({
  key: "atom_whatsappAddress",
  default: null
});

export const atom_depositText = atom({
  key: "atom_depositText",
  default: null
});

export const atom_tradeType = atom({
  key: "atom_tradeType",
  default: "XAUUSD"
});