import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStores } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";

const Announcements = () => {
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState("");
  const confirmBack = () => {
    navigate("/client_dashboard", { replace: true });
  };

  const getImage = async () => {
    const fileAssetData = await getStores("FileAsset");
    const fileAsset = Object.entries(fileAssetData).filter(
      (one) => one[1].fileName === "announcements.jpg"
    );
    if (IsEmpty(fileAsset)) {
      return;
    } else {
      setFileUrl(fileAsset[0][1].path);
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <div>
      <div className="font-sans text-lg font-normal max-sm:text-xs">
        <div className="m-10">
          <button
            className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-10 py-3 text-white hover:bg-blue-500"
            onClick={confirmBack}
          >
            Go to Dashboard
          </button>
        </div>
        {IsEmpty(fileUrl) ? null : (
          <div>
            <img src={fileUrl} alt="reward" width="80%" className="m-auto" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Announcements;
