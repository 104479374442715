import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import AdminDashboard from "./pages/admin_dashboard";
import SendEthPanel from "./pages/send_eth_panel";
import ClientsPanel from "./pages/clients_panel";
import Reward from "./pages/reward";
import AccountDashboard from "./pages/account_dashboard";
import Event from "./pages/event";
import UploadPanel from "./pages/upload_panel";
import Income from "./pages/income";
import Announcements from "./pages/announce";
import { mainnet, bsc, polygon, sepolia } from "wagmi/chains";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { getDefaultConfig } from "connectkit";
import reportWebVitals from "./reportWebVitals";
import GoldTradeDashboard from "./pages/goldtrade_dashboard";
import GoldAdminDashboard from "./pages/goldadmin_dashboard";
import GoldClientsPanel from "./pages/gold_clients_panel";
import CommissionDetails from "./pages/commission_details";

// const { chains, publicClient } = configureChains(
//   [mainnet, bsc, polygon, sepolia],
//   [publicProvider()]
// );

// const { connectors } = getDefaultWallets({
//   appName: "wildbase_genesis",
//   projectId: "3521224b7087fc7a73b625657ad5dd6f",
//   chains,
// });

// const wagmiConfigData = createConfig({
//   autoConnect: false,
//   chains,
//   // connectors,
//   publicClient
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <Router>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/admin_dashboard" element={<AdminDashboard />} />
        <Route
          exact
          path="/goldadmin_dashboard"
          element={<GoldAdminDashboard />}
        />
        <Route exact path="/goldclient_list" element={<GoldClientsPanel />} />
        <Route exact path="/client_dashboard" element={<AccountDashboard />} />
        <Route
          exact
          path="/goldtrade_dashboard"
          element={<GoldTradeDashboard />}
        />
        <Route
          exact
          path="/commission_details"
          element={<CommissionDetails />}
        />
        <Route exact path="/sendeth_page" element={<SendEthPanel />} />
        <Route exact path="/client_list" element={<ClientsPanel />} />
        <Route exact path="/reward_page" element={<Reward />} />
        <Route exact path="/event_page" element={<Event />} />
        <Route exact path="/income_page" element={<Income />} />
        <Route exact path="/announce_page" element={<Announcements />} />
        <Route exact path="/upload_page" element={<UploadPanel />} />
      </Routes>
    </Router>
  </RecoilRoot>
);

reportWebVitals();
