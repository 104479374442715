import React, { useEffect, useState } from "react";
import {
  atom_address,
  atom_write_locked_item,
  isTextLockedModalShow
} from "../atom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getStores, updateStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";

const LockedTextWrite = () => {
  const [locked_write_item, setLockedWriteItem] = useRecoilState(
    atom_write_locked_item
  );
  const [lockedText, setLockedText] = useState("");
  const setLockedTextModalShow = useSetRecoilState(isTextLockedModalShow);
  const address = useRecoilValue(atom_address);

  const handleWrite = () => {
    if (locked_write_item === null) {
      toast("Write failed. Missing address");
      return;
    }
    const newRes = { ...locked_write_item, locked_text: lockedText };
    updateStore("Client", locked_write_item.id, newRes);
    setLockedWriteItem(null);
    setLockedTextModalShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (locked_write_item == null) return;

      try {
        const clientData = await getStores("Client");

        if (!IsEmpty(clientData)) {
          const clientUser = Object.entries(clientData).filter(
            (item) =>
              item[1].address === locked_write_item.address &&
              item[1].admin === address
          );

          if (clientUser.length > 0) {
            setLockedText(clientUser[0][1]?.locked_text);
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="font-sans text-lg font-normal max-sm:text-xs">
        <div>
          <p className="text-black text-xl mb-2">Write locked text here</p>
          <textarea
            id="txt_adwrite"
            name="txt_adwrite"
            value={lockedText}
            onChange={(e) => setLockedText(e.target.value)}
            rows={5}
            className="w-full rounded-sm border border-black p-3 text-black"
            // placeholder="Input Your ETH Address Here."
          />
          <button
            className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-6 py-2 float-right text-white hover:bg-blue-500"
            onClick={handleWrite}
          >
            Write
          </button>
        </div>
      </div>
    </div>
  );
};

export default LockedTextWrite;
