import React, { useEffect, useState } from "react";
import { isDepositAdvertiseModalShow } from "../atom";
import { useSetRecoilState } from "recoil";
import { getStores, saveStore, updateStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";

const DepositAdvertise = () => {
  const [depositText, setDepositText] = useState("");
  const [tgText, setTgText] = useState("");
  const [ethAddr, setEthAddr] = useState("");
  const [btcAddr, setBtcAddr] = useState("");
  const [trcAddr, setTrcAddr] = useState("");
  const [whatsappAddr, setWhatsappAddr] = useState("");
  const setDepositAdvertiseModalShow = useSetRecoilState(
    isDepositAdvertiseModalShow
  );

  const handleConfirm = async () => {
    try {
      const adData = await getStores("Advertise");
      if (!IsEmpty(adData)) {
        const data = Object.entries(adData);
        const newRes = {
          ...data[0][1],
          contents: depositText,
          telegram: tgText,
          ethAddress: ethAddr,
          btcAddress: btcAddr,
          trcAddress: trcAddr,
          whatsappAddress: whatsappAddr
        };
        updateStore("Advertise", data[0][1].id, newRes);
      } else {
        const newRes = {
          contents: depositText,
          telegram: tgText,
          ethAddress: ethAddr,
          btcAddress: btcAddr,
          trcAddress: trcAddr,
          whatsappAddress: whatsappAddr
        };
        saveStore("Advertise", newRes);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
    setDepositAdvertiseModalShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adData = await getStores("Advertise");
        const data = Object.entries(adData);
        if (!IsEmpty(adData)) {
          setDepositText(data[0][1].contents);
          setTgText(data[0][1].telegram);
          setEthAddr(data[0][1].ethAddress);
          setBtcAddr(data[0][1].btcAddress);
          setTrcAddr(data[0][1].trcAddress);
          setWhatsappAddr(data[0][1].whatsappAddress);
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="font-sans text-lg font-normal max-sm:text-xs ">
        <div>
          <p className="text-black text-xl mb-2">
            Write Deposit Form Text Here
          </p>
          <textarea
            id="txt_depositform"
            name="txt_depositform"
            value={depositText}
            onChange={(e) => setDepositText(e.target.value)}
            rows={5}
            className="w-full rounded-sm border border-black p-3 text-black"
            // placeholder="Input Your ETH Address Here."
          />
          <input
            type="text"
            id="txt_tg"
            name="txt_tg"
            className="w-full rounded-sm border p-2 border-black mb-4 text-black"
            placeholder="Input Telegram Address Here."
            value={tgText}
            onChange={(e) => {
              setTgText(e.target.value);
            }}
          />
          <input
            type="text"
            id="txt_ethAddr"
            name="txt_ethAddr"
            className="w-full rounded-sm border p-2 border-black mb-4 text-black"
            placeholder="Input ETH/USDT Address Here."
            value={ethAddr}
            onChange={(e) => {
              setEthAddr(e.target.value);
            }}
          />
          <input
            type="text"
            id="txt_btcAddr"
            name="txt_btcAddr"
            className="w-full rounded-sm border p-2 border-black mb-4 text-black"
            placeholder="Input BTC Address Here."
            value={btcAddr}
            onChange={(e) => {
              setBtcAddr(e.target.value);
            }}
          />
          <input
            type="text"
            id="txt_trcAddr"
            name="txt_trcAddr"
            className="w-full rounded-sm border p-2 border-black mb-4 text-black"
            placeholder="Input TRC Address Here."
            value={trcAddr}
            onChange={(e) => {
              setTrcAddr(e.target.value);
            }}
          />
          <input
            type="text"
            id="txt_whatsappAddr"
            name="txt_whatsappAddr"
            className="w-full rounded-sm border p-2 border-black mb-4 text-black"
            placeholder="Input Whatapp Address Here."
            value={whatsappAddr}
            onChange={(e) => {
              setWhatsappAddr(e.target.value);
            }}
          />
          <button
            className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-6 py-2 float-right text-white hover:bg-blue-500"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositAdvertise;
