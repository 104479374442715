import React, { useEffect, useState } from "react";
import bottom_mark from "../assets/img/bottom_mark.png";
import Modal from "../components/Modal";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  atom_address,
  atom_email,
  atom_refresh,
  isDepositAdvertiseModalShow
} from "../atom";
import { useNavigate } from "react-router-dom";
import { getStores } from "../api/crud";
import { reduceString } from "../utils/utilities";
import CollapsibleTable from "../components/CollapsibleTable";
import DepositAdvertise from "../components/DepositAdvertise";
import TextModal from "../components/TextModal";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const refresh = useRecoilValue(atom_refresh);
  const address = useRecoilValue(atom_address);
  const [tblItems, setTblItems] = useState([]);
  const [advertiseModal, setAdvertiseModal] = useRecoilState(
    isDepositAdvertiseModalShow
  );

  let tblBody = "";

  const handleClientList = () => {
    navigate("/client_list", { replace: true });
  };

  const handleSendEth = () => {
    navigate("/sendeth_page", { replace: true });
  };

  const handleAdvertise = () => {
    setAdvertiseModal(true);
  };

  const handleUpload = () => {
    navigate("/upload_page", { replace: true });
  };

  const getTblValues = async () => {
    const tblData = await getStores("Transaction");
    if (tblData === null) {
      setTblItems([]);
      return;
    }
    const items = Object.entries(tblData)
      .filter((item) => item[1].admin === address)
      .map((item) => {
        let reqInfo = "";
        if (item[1].action === "Sent") {
          reqInfo = `Send ${item[1].deposit} ETH`;
        } else {
          reqInfo = `Withdraw ${item[1].withdrawal} USDT`;
        }
        let addData = {
          id: item[1].id,
          date: item[1].date,
          name: item[1].name,
          email: item[1].email,
          address: item[1].address,
          req: reqInfo,
          action: item[1].action
        };
        return addData;
      });

    setTblItems(items);
  };

  useEffect(() => {
    getTblValues();
  }, [refresh]);

  // useEffect(() => {
  //   const setWinLoss = async () => {
  //     const tblData = await getStores("AdminUser");
  //     if (tblData === null) {
  //       return;
  //     }
  //     const items = Object.entries(tblData).filter(
  //       (item) => item[1].address === address
  //     );
  //     if (!IsEmpty(items)) {
  //       setGoldWin(items[0][1].win);
  //     }
  //   };
  //   setWinLoss();
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Code to be executed at the defined interval
      getTblValues();
    }, 10000); // Interval set to 1 second (1000 milliseconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);

  const data = {};

  tblItems.forEach((item) => {
    if (!data[item.name]) {
      data[item.name] = {
        name: item.name,
        address: item.address,
        historyRow: []
      };
    }
    data[item.name].historyRow.push(item);
  });

  const rows = Object.values(data);

  const filteredAndSortedItems = tblItems
    .filter((item) => item.req.includes("Withdraw"))
    .sort((a, b) => parseInt(b.id) - parseInt(a.id));

  tblBody = filteredAndSortedItems?.map((item) => {
    return (
      <tr className="text-xl max-sm:text-sm text-left">
        <td>{item.date}</td>
        <td>{reduceString(item.name)}</td>
        {/* <td>{reduceString(item.email)}</td> */}
        <td>{item.address}</td>
        <td>{item.req}</td>
      </tr>
    );
  });

  return (
    <div className="max-sm:m-1 text-white font-sans text-2xl font-bold bg-slate-950">
      <div className="flex flex-row max-sm:flex-col gap-4 max-sm:gap-2">
        <div className="w-56 max-sm:w-full">
          <div className="flex flex-col bg-indigo-900  gap-7 max-sm:gap-2 p-5 max-sm:p-2">
            <button
              className="bg-indigo-400 px-5 py-7 max-sm:py-3 rounded-xl hover:bg-indigo-500 text-left"
              onClick={handleClientList}
            >
              Client List
            </button>
            <button
              className="bg-indigo-400 px-5 py-7 max-sm:py-3 rounded-xl cursor-pointer hover:bg-indigo-500 text-left"
              onClick={handleSendEth}
            >
              Send BTC(ETH)
            </button>
            <button
              className="bg-indigo-400 px-5 py-7 max-sm:py-3 rounded-xl cursor-pointer hover:bg-indigo-500 text-left"
              onClick={handleAdvertise}
            >
              Deposit Form
            </button>
            <button
              className="bg-indigo-400 px-5 py-7 max-sm:py-3 rounded-xl cursor-pointer hover:bg-indigo-500 text-left"
              onClick={handleUpload}
            >
              Upload Manager
            </button>
            {/* <div className="bg-indigo-400 px-5 py-7 max-sm:py-3 rounded-xl cursor-pointer hover:bg-indigo-500">
              Event
            </div> */}
            {/* <button
              className="bg-indigo-400 px-5 py-7 max-sm:py-3 rounded-xl cursor-pointer hover:bg-indigo-500 text-left"
              onClick={handleWithdrawPanel}
            >
              Withdraw
            </button> */}
            <div className="bg-indigo-400 px-5 py-7 max-sm:py-3 rounded-xl h-dashboard_full_admin max-sm:h-24">
              <p className="text-center text-black  text-lg font-bold m-3">
                Wallet Address
              </p>
              <div className=" text-white  text-lg font-normal">
                {/* <p>Email:</p>
                <p>{email}</p> */}
                <p className="text-center">{reduceString(address)}</p>
              </div>
              {/* <p className="mt-4 text-sm break-all text-blue-800">
                You can click below switch to set the gold trade to win or loss
              </p>
              <div className="text-center mt-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={goldWin}
                      onChange={handleGoldWinLoss}
                      color="secondary"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={goldWinLabel}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className=" bg-gray-200 w-full mr-5 mt-5 rounded-xl">
          <div className="mt-8 overflow-auto p-2 max-sm:text-sm h-dashinfo_full_admin border-y-2 border-gray-500">
            <CollapsibleTable rows={rows} />
          </div>
          <div className="mt-2 overflow-auto p-2 max-sm:text-sm h-dashboard_withdraw_admin border-y-2 border-gray-500">
            <table className="table-auto w-full">
              <thead className="text-gray-800">
                <tr className="text-left">
                  <th>Date</th>
                  <th>Client Name</th>
                  {/* <th>Email</th> */}
                  <th>Wallet Address</th>
                  <th>Transaction Req</th>
                </tr>
              </thead>
              <tbody className="text-center text-gray-600">{tblBody}</tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex text-center w-full justify-center">
        <img src={bottom_mark} alt="mark" width="800" />
      </div>
      <Modal
        openModal={advertiseModal}
        setOpenModal={setAdvertiseModal}
        children={<DepositAdvertise />}
        showClose={false}
      />
    </div>
  );
};

export default AdminDashboard;
