import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atom_address,
  atom_refresh,
  isMiningToGoldWithdrawShow
} from "../atom";
import { useNavigate } from "react-router-dom";
import { getStores, updateStore } from "../api/crud";
import {
  convertToNewYorkSeven,
  getNewYorkDate,
  percentPaid,
  subtractDates
} from "../utils/utilities";
import moment from "moment";
import { toast } from "react-toastify";
import { IsEmpty } from "../utils/isEmpty";

const MiningToGoldWithdraw = ({ dashInfo }) => {
  const navigate = useNavigate();
  const [withdrawal, setWithdrawal] = useState(0);
  const setToGoldWithdrawModal = useSetRecoilState(isMiningToGoldWithdrawShow);
  const [refresh, setRefresh] = useRecoilState(atom_refresh);
  const address = useRecoilValue(atom_address);

  const withdrawHandle = async () => {
    if (withdrawal > dashInfo.withdrawable_usdt) {
      toast(
        "You can't withdraw more than " + dashInfo.withdrawable_usdt + " USDT"
      );
      return;
    }
    const clientInfos = await getStores("Client");
    if (IsEmpty(clientInfos)) {
      navigate("/", { replace: true });
      return;
    }

    const clientInfo = Object.entries(clientInfos).filter(
      (one) => one[1]?.address === address
    );
    if (IsEmpty(clientInfo)) {
      navigate("/", { replace: true });
      return;
    }
    const clientAssetData = await getStores("ClientAsset");
    if (clientAssetData === null) {
      return;
    } else {
      const clientAsset = Object.entries(clientAssetData).filter(
        (one) => one[1].address === address
      );
      let new_withdrawable_usdt =
        parseFloat(dashInfo.withdrawable_usdt) - parseFloat(withdrawal);
      let new_usdt = 0;
      let new_date = clientAsset[0][1].date;
      // let withdrawn = parseFloat(clientAsset[0][1].withdrawn);
      // withdrawn += parseFloat(withdrawal);
      const prevDate = new Date(clientAsset[0][1]?.date);
      const curDate = await getNewYorkDate();
      const interDays = subtractDates(curDate, convertToNewYorkSeven(prevDate));
      let paidMoney = 0;
      if (new_withdrawable_usdt > dashInfo.usdt) {
        new_usdt = clientAsset[0][1].usdt;

        if (clientInfo[0][1]?.profit_active === "active")
          paidMoney = new_usdt * 0.005 * interDays;
        else if (clientInfo[0][1]?.profit_active_10 === "active")
          paidMoney = new_usdt * 0.1 * interDays;
        else if (clientInfo[0][1]?.profit_active_20 === "active")
          paidMoney = new_usdt * 0.2 * interDays;
        else if (clientInfo[0][1]?.profit_active_50 === "active")
          paidMoney = new_usdt * 0.5 * interDays;
        else paidMoney = new_usdt * percentPaid(new_usdt) * interDays;
      } else {
        new_usdt = new_withdrawable_usdt;
        new_date = moment(curDate).format("YYYY-MM-DD HH:mm:ss");
      }
      new_withdrawable_usdt -= paidMoney;
      new_withdrawable_usdt = Math.round(new_withdrawable_usdt * 100) / 100;
      new_usdt = Math.round(new_usdt * 100) / 100 - parseFloat(withdrawal);
      if (new_usdt < 0) new_usdt = 0;
      let fee = 0;
      try {
        fee = parseFloat(clientAsset[0][1]?.fee);
      } catch (ex) {}
      setWithdrawal(0);
      let updateAsset = {
        name: clientAsset[0][1].name,
        email: clientAsset[0][1].email,
        address: clientAsset[0][1].address,
        oneEth: clientAsset[0][1].oneEth,
        deposit: clientAsset[0][1].deposit,
        usdt: new_usdt,
        // fee: clientAsset[0][1].fee,
        fee: fee,
        date: new_date,
        action: clientAsset[0][1].action,
        // withdrawn: withdrawn,
        withdrawable: new_withdrawable_usdt
      };

      let flag2 = updateStore("ClientAsset", clientAsset[0][1].id, updateAsset);
      if (flag2) {
        // const data = {
        //   name: clientAsset[0][1].name,
        //   email: clientAsset[0][1].email,
        //   admin: clientAsset[0][1].admin,
        //   address: clientAsset[0][1].address,
        //   oneEth: clientAsset[0][1].oneEth,
        //   deposit: clientAsset[0][1].deposit,
        //   fee: clientAsset[0][1].fee,
        //   date: moment(curDate).format("YYYY-MM-DD hh:mm:ss"),
        //   withdrawal,
        //   action: "Withdrawn"
        // };
        // saveStore("Transaction", data);

        let newBalance =
          clientInfo[0][1].trade_balance === "" ||
          clientInfo[0][1].trade_balance === undefined ||
          clientInfo[0][1].trade_balance === null
            ? 0
            : clientInfo[0][1].trade_balance;
        newBalance = parseFloat(newBalance) + parseFloat(withdrawal);
        const updated = { ...clientInfo[0][1], trade_balance: newBalance };
        await updateStore("Client", clientInfo[0][1].id, updated);
        toast("Withdraw Success!");
        setRefresh(!refresh);
      } else {
        toast("Withdraw Failed!");
      }
    }
    setToGoldWithdrawModal(false);
  };

  return (
    <div className="items-center">
      <div className="grid grid-rows-2 grid-cols-3 text-black text-lg font-normal font-sans gap-3">
        <div>
          <p className="text-right">Amount of Withdrawal:</p>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="txt_withdraw"
            name="txt_withdraw"
            className="w-full rounded-sm border p-3"
            placeholder="Input User Id Here."
            value={withdrawal}
            onChange={(e) => {
              setWithdrawal(e.target.value);
            }}
          />
        </div>

        <div className="col-span-3 text-right mt-2">
          <button
            className="rounded-xl bg-gray-800 shadow-md shadow-gray-700 px-6 py-3 text-white hover:bg-gray-500"
            onClick={withdrawHandle}
          >
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
};

export default MiningToGoldWithdraw;
