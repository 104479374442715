import React, { useState } from "react";
import { getStores, saveStore } from "../api/crud";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atom_address, atom_refresh, isClientAddShow } from "../atom";
import { toast } from "react-toastify";
import { IsEmpty } from "../utils/isEmpty";

const ClientAdd = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const setClientAddModal = useSetRecoilState(isClientAddShow);
  const [refresh, setRefresh] = useRecoilState(atom_refresh);
  const adminAddress = useRecoilValue(atom_address);
  const handleAdd = async () => {
    const clients = await getStores("Client");
    if (!IsEmpty(clients)) {
      const itemList = Object.entries(clients).filter(
        (one) => one[1].address === address
      );
      if (itemList.length > 0) {
        toast("User already exists!");
        return;
      }
    }
    const data = {
      name,
      email,
      address,
      admin: adminAddress,
      profit_active: "non_active",
      event: "show",
      reward: "show",
      withdraw_status: "unlocked",
      calc_status: "show",
      announce_status: "show"
    };
    saveStore("Client", data);
    setClientAddModal(false);
    setRefresh(!refresh);
  };

  return (
    <div className="items-center">
      <div className="grid grid-rows-3 grid-cols-3 text-black text-lg font-normal font-sans gap-3">
        <div>
          <p className=" py-3 text-right">Name:</p>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="txt_username"
            name="txt_username"
            className="w-full rounded-sm border p-3"
            placeholder="Input Your Email Here."
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div>
          <p className=" py-3 text-right">Email:</p>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="txt_email"
            name="txt_email"
            className="w-full rounded-sm border p-3"
            placeholder="Input Client's Email Here."
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div>
          <p className=" py-3 text-right">Eth Address:</p>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="txt_address"
            name="txt_address"
            className="w-full rounded-sm border p-3"
            placeholder="Input Client's ETH Address Here."
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </div>
        <div className="col-span-3 text-right mt-2">
          <button
            className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-6 py-3 text-white hover:bg-blue-500"
            onClick={handleAdd}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientAdd;
