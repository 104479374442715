import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atom_address, atom_refresh, isWithdrawShow } from "../atom";
import { toast } from "react-toastify";
import { getStores, saveStore, updateStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";
import moment from "moment";
import { getNewYorkDate } from "../utils/utilities";

const GoldWithdraw = ({ balance }) => {
  const [withdraw, setWithdraw] = useState(0);
  const setWithdrawModal = useSetRecoilState(isWithdrawShow);
  const address = useRecoilValue(atom_address);
  const [refresh, setRefresh] = useRecoilState(atom_refresh);

  const withdrawHandle = async () => {
    if (balance < withdraw) {
      toast("You can't withdraw amount more than your balance.");
      return;
    }
    setWithdrawModal(false);
    const tblData = await getStores("Client");
    if (tblData === null) {
      return;
    }
    const curDate = await getNewYorkDate();
    const items = Object.entries(tblData).filter(
      (item) => item[1].address === address
    );
    if (!IsEmpty(items)) {
      let newWithdrawal = 0;
      try {
        newWithdrawal = items[0][1].withdrawal;
      } catch (error) {}
      newWithdrawal += parseFloat(withdraw);
      const newRes = { ...items[0][1], withdrawal: newWithdrawal };
      await updateStore("Client", items[0][1].id, newRes);
      const data = {
        name: items[0][1].name,
        email: items[0][1].email,
        admin: items[0][1].admin,
        address: items[0][1].address,
        oneEth: "",
        deposit: "",
        fee: "",
        date: moment(curDate).format("YYYY-MM-DD hh:mm:ss"),
        withdrawal: withdraw,
        action: "Withdrawn"
      };
      await saveStore("Transaction", data);
      setRefresh(!refresh);
    }
  };

  return (
    <div className="items-center">
      <div className="grid grid-rows-2 grid-cols-3 text-black text-lg font-normal font-sans gap-3">
        <div>
          <p className="text-right">Amount of Withdrawal:</p>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="txt_withdraw"
            name="txt_withdraw"
            className="w-full rounded-sm border p-3"
            placeholder="Input User Id Here."
            value={withdraw}
            onChange={(e) => {
              setWithdraw(e.target.value);
            }}
          />
        </div>

        <div className="col-span-3 text-right mt-2">
          <button
            className="rounded-xl bg-gray-800 shadow-md shadow-gray-700 px-6 py-3 text-white hover:bg-gray-500"
            onClick={withdrawHandle}
          >
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoldWithdraw;
