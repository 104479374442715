import React, { useEffect, useState } from "react";
import { getStores, updateStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";
import { useSetRecoilState } from "recoil";
import { isImagePopupShow } from "../atom";
import { toast } from "react-toastify";

const AdImage = ({ address }) => {
  const [fileUrl, setFileUrl] = useState("");
  const setImagePopupShow = useSetRecoilState(isImagePopupShow);

  const claimHandle = async () => {
    try {
      const clientData = await getStores("Client");

      if (!IsEmpty(clientData)) {
        const clientUser = Object.entries(clientData).filter(
          (item) => item[1].address === address
        );

        if (clientUser.length > 0) {
          const newRes = { ...clientUser[0][1], claimed: "true" };
          updateStore("Client", clientUser[0][1].id, newRes);
          toast("Sent your request!");
          setImagePopupShow(false);
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientData = await getStores("Client");

        if (!IsEmpty(clientData)) {
          const clientUser = Object.entries(clientData).filter(
            (item) => item[1].address === address
          );

          if (clientUser.length > 0) {
            if (IsEmpty(clientUser[0][1]?.ad_image)) {
              setImagePopupShow(false);
              return;
            }
            setFileUrl(clientUser[0][1]?.ad_image);
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="font-sans text-lg font-normal max-sm:text-xs">
        <div className="m-10"></div>
        {IsEmpty(fileUrl) ? null : (
          <>
            <div>
              <img
                src={fileUrl}
                alt="reward"
                width="90%"
                className="m-auto shadow-lg shadow-slate-800"
              />
            </div>
            <div className="mt-3 text-center">
              <button
                className="rounded-xl bg-blue-900 shadow-md shadow-gray-700 px-6 py-2 text-white hover:bg-blue-500"
                onClick={claimHandle}
              >
                Claim Now
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdImage;
