import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atom_address,
  atom_gold_trade_item,
  atom_image_ad_item,
  atom_refresh,
  atom_write_ad_item,
  atom_write_locked_item,
  isClientAddShow,
  isImageAdModalShow,
  isTextAdModalShow,
  isTextLockedModalShow,
  isTradeBalanceModalShow
} from "../atom";
import { useNavigate } from "react-router-dom";
import { deleteStore, getStores, updateStore } from "../api/crud";
import { ToastContainer, toast } from "react-toastify";
import ClientAdd from "../components/ClientAdd";
import Modal from "../components/Modal";
import { IsEmpty } from "../utils/isEmpty";
import imgLocked from "../assets/img/locked.png";
import imgUnlocked from "../assets/img/unlocked.png";
import {
  convertToNewYorkSeven,
  getNewYorkDate,
  percentPaid,
  reduceString,
  subtractDates
} from "../utils/utilities";
import moment from "moment";
import AdTextWrite from "../components/AdTextWrite";
import LockedTextWrite from "../components/LockedTextWrite";
import AdImageSet from "../components/AdImageSet";
import GoldTradeBalance from "../components/GoldTradeBalance";
import clsx from "clsx";

const ClientsPanel = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [clientAddModal, setClientAddModal] = useRecoilState(isClientAddShow);
  const setImageAdItem = useSetRecoilState(atom_image_ad_item);
  const setWriteAdItem = useSetRecoilState(atom_write_ad_item);
  const setWriteLockedItem = useSetRecoilState(atom_write_locked_item);
  const setTradeBalanceItem = useSetRecoilState(atom_gold_trade_item);
  const [adImageModal, setAdImageModal] = useRecoilState(isImageAdModalShow);
  const [adTextModal, setAdTextModal] = useRecoilState(isTextAdModalShow);
  const [tradeBalanceModal, setTradeBalanceModal] = useRecoilState(
    isTradeBalanceModalShow
  );
  const [lockedTextModal, setLockedTextModal] = useRecoilState(
    isTextLockedModalShow
  );
  const [refresh, setRefresh] = useRecoilState(atom_refresh);
  const address = useRecoilValue(atom_address);
  const handleAdd = () => {
    setClientAddModal(true);

    // const transData = await getStores("Transaction");
    // Object.entries(transData).forEach((one) => {
    //   const newRes = {
    //     ...one[1],
    //     admin: "qaz128128"
    //   };
    //   updateStore("Transaction", one[1].id, newRes);
    // });
  };

  const handleDelete = async (item) => {
    deleteStore("Client", item.id);
    const assetData = await getStores("ClientAsset");
    if (!IsEmpty(assetData)) {
      const itemList = Object.entries(assetData).filter(
        (one) => one[1].address === item.address
      );
      itemList.forEach((one) => {
        deleteStore("ClientAsset", one[1].id);
      });
    }
    const transData = await getStores("Transaction");
    if (!IsEmpty(transData)) {
      if (transData === null) {
        return;
      }
      const transList = Object.entries(transData).filter(
        (one) => one[1].email === item.email && one[1].address === item.address
      );
      transList.forEach((one) => {
        deleteStore("Transaction", one[1].id);
      });
    }
    setRefresh(!refresh);
  };

  const handleEventView = (item) => {
    let stat = "";
    if (item.event === "show") stat = "hide";
    else stat = "show";
    const newRes = { ...item, event: stat };
    updateStore("Client", item.id, newRes);
    setRefresh(!refresh);
  };

  const handleActivateProfit = (item) => {
    let stat_0p5 = "";
    let stat_10 = "";
    let stat_20 = "";
    let stat_50 = "";
    if (item.profit_active === "active") stat_0p5 = "non_active";
    else {
      stat_0p5 = "active";
      stat_10 = "non_active";
      stat_20 = "non_active";
      stat_50 = "non_active";
    }
    const newRes = {
      ...item,
      profit_active: stat_0p5,
      profit_active_10: stat_10,
      profit_active_20: stat_20,
      profit_active_50: stat_50
    };
    updateStore("Client", item.id, newRes);
    changeWithdrawableOnProfitChange(item);
    setRefresh(!refresh);
  };

  const changeWithdrawableOnProfitChange = async (item) => {
    const newYorkDate = await getNewYorkDate();
    const clientAssetData = await getStores("ClientAsset");
    if (clientAssetData === null) {
      return;
    } else {
      const clientAsset = Object.entries(clientAssetData).filter(
        (one) => one[1].address === item.address
      );
      if (IsEmpty(clientAsset[0])) return;
      let usdt = clientAsset[0][1]?.usdt;
      let withdrawable_usdt = clientAsset[0][1]?.withdrawable;
      let prevDate = new Date(clientAsset[0][1]?.date);
      let interDays = 0;
      let paidMoney = 0;

      interDays = subtractDates(newYorkDate, convertToNewYorkSeven(prevDate));
      if (clientAsset[0][1]?.profit_active === "active")
        paidMoney = usdt * 0.005 * interDays;
      else if (clientAsset[0][1]?.profit_active_10 === "active")
        paidMoney = usdt * 0.1 * interDays;
      else if (clientAsset[0][1]?.profit_active_20 === "active")
        paidMoney = usdt * 0.2 * interDays;
      else if (clientAsset[0][1]?.profit_active_50 === "active")
        paidMoney = usdt * 0.5 * interDays;
      else paidMoney = usdt * percentPaid(usdt) * interDays;

      withdrawable_usdt =
        Math.round((withdrawable_usdt + paidMoney) * 100) / 100.0;

      let updateAsset = {
        name: clientAsset[0][1].name,
        email: clientAsset[0][1].email,
        address: clientAsset[0][1].address,
        admin: clientAsset[0][1].admin,
        oneEth: clientAsset[0][1].oneEth,
        deposit: clientAsset[0][1].deposit,
        usdt,
        fee: clientAsset[0][1].fee,
        date: moment(newYorkDate).format("YYYY-MM-DD HH:mm:ss"),
        action: clientAsset[0][1].action,
        withdrawn: clientAsset[0][1].withdrawn,
        withdrawable: withdrawable_usdt
      };
      updateStore("ClientAsset", clientAsset[0][1].id, updateAsset);
    }
  };

  const handleActivateProfit_10 = (item) => {
    let stat_0p5 = "";
    let stat_10 = "";
    let stat_20 = "";
    let stat_50 = "";
    if (item.profit_active_10 === "active") stat_10 = "non_active";
    else {
      stat_0p5 = "non_active";
      stat_10 = "active";
      stat_20 = "non_active";
      stat_50 = "non_active";
    }
    const newRes = {
      ...item,
      profit_active: stat_0p5,
      profit_active_10: stat_10,
      profit_active_20: stat_20,
      profit_active_50: stat_50
    };
    updateStore("Client", item.id, newRes);
    changeWithdrawableOnProfitChange(item);
    setRefresh(!refresh);
  };

  const handleActivateProfit_20 = (item) => {
    let stat_0p5 = "";
    let stat_10 = "";
    let stat_20 = "";
    let stat_50 = "";
    if (item.profit_active_20 === "active") stat_20 = "non_active";
    else {
      stat_0p5 = "non_active";
      stat_10 = "non_active";
      stat_20 = "active";
      stat_50 = "non_active";
    }
    const newRes = {
      ...item,
      profit_active: stat_0p5,
      profit_active_10: stat_10,
      profit_active_20: stat_20,
      profit_active_50: stat_50
    };
    updateStore("Client", item.id, newRes);
    changeWithdrawableOnProfitChange(item);
    setRefresh(!refresh);
  };

  const handleActivateProfit_50 = (item) => {
    let stat_0p5 = "";
    let stat_10 = "";
    let stat_20 = "";
    let stat_50 = "";
    if (item.profit_active_50 === "active") stat_50 = "non_active";
    else {
      stat_0p5 = "non_active";
      stat_10 = "non_active";
      stat_20 = "non_active";
      stat_50 = "active";
    }
    const newRes = {
      ...item,
      profit_active: stat_0p5,
      profit_active_10: stat_10,
      profit_active_20: stat_20,
      profit_active_50: stat_50
    };
    updateStore("Client", item.id, newRes);
    changeWithdrawableOnProfitChange(item);
    setRefresh(!refresh);
  };

  const handleRewardView = (item) => {
    let stat = "";
    if (item.reward === "show") stat = "hide";
    else stat = "show";
    const newRes = { ...item, reward: stat };
    updateStore("Client", item.id, newRes);
    setRefresh(!refresh);
  };

  const handleWithdrawStatus = (item) => {
    let stat = "";
    if (item.withdraw_status === "locked") stat = "unlocked";
    else stat = "locked";
    const newRes = { ...item, withdraw_status: stat };
    updateStore("Client", item.id, newRes);
    setRefresh(!refresh);
  };

  const handleCalcStatus = (item) => {
    let stat = "";
    if (item.calc_status === "show") stat = "hide";
    else stat = "show";
    const newRes = { ...item, calc_status: stat };
    updateStore("Client", item.id, newRes);
    setRefresh(!refresh);
  };

  const handleAnnounceStatus = (item) => {
    let stat = "";
    if (item.announce_status === "show") stat = "hide";
    else stat = "show";
    const newRes = { ...item, announce_status: stat };
    updateStore("Client", item.id, newRes);
    setRefresh(!refresh);
  };

  const handleAdText = (item) => {
    setWriteAdItem(item);
    setAdTextModal(true);
  };

  const handleAdImage = (item) => {
    setImageAdItem(item);
    setAdImageModal(true);
  };

  const handleLockedText = (item) => {
    setWriteLockedItem(item);
    setLockedTextModal(true);
  };

  const handleTradeBalance = (item) => {
    setTradeBalanceItem(item);
    setTradeBalanceModal(true);
  };

  const confirmBack = () => {
    navigate("/admin_dashboard", { replace: true });
  };

  const getTableData = async () => {
    const tblData = await getStores("Client");
    if (tblData === null) {
      setTableData([]);
      return;
    }
    const itemList = Object.entries(tblData)
      .filter((item) => item[1].admin === address)
      .map((item) => {
        return {
          id: item[1].id,
          name: item[1].name,
          email: item[1].email,
          address: item[1].address,
          claimed: IsEmpty(item[1].claimed) ? "" : item[1].claimed,
          ad_image: IsEmpty(item[1].ad_image) ? "" : item[1].ad_image,
          ad_text: IsEmpty(item[1].ad_text) ? "" : item[1].ad_text,
          locked_text: IsEmpty(item[1].locked_text) ? "" : item[1].locked_text,
          profit_active: item[1].profit_active,
          profit_active_10: item[1].profit_active_10,
          profit_active_20: item[1].profit_active_20,
          profit_active_50: item[1].profit_active_50,
          event: item[1].event,
          reward: item[1].reward,
          withdraw_status: item[1].withdraw_status,
          calc_status: item[1].calc_status,
          announce_status: item[1].announce_status,
          trade_balance:
            item[1].trade_balance === undefined ||
            item[1].trade_balance === null
              ? 0
              : item[1].trade_balance
        };
      });
    setTableData(itemList);
  };

  useEffect(() => {
    getTableData();
  }, [refresh]);

  let tblBody = "";
  tblBody = tableData?.map((item) => {
    return (
      <tr className=" text-base hover:bg-gray-800">
        <td className=" text-xs font-extralight">{reduceString(item.name)}</td>
        <td className="text-xs">{item.address}</td>
        {/* <td className="px-1">{item.address}</td> */}
        <td className="px-1 text-sm">
          {item.claimed === "true" ? "Claimed" : ""}
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 py-1 text-white hover:bg-indigo-700 text-sm max-sm:text-xs"
            onClick={() => handleAdImage(item)}
          >
            Set Image
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleAdText(item)}
          >
            Write
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleLockedText(item)}
          >
            Write
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleActivateProfit(item)}
          >
            {item.profit_active === "active" ? "Non Active" : "Active"}
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleActivateProfit_10(item)}
          >
            {item.profit_active_10 === "active" ? "Non Active" : "Active"}
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleActivateProfit_20(item)}
          >
            {item.profit_active_20 === "active" ? "Non Active" : "Active"}
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleActivateProfit_50(item)}
          >
            {item.profit_active_50 === "active" ? "Non Active" : "Active"}
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-red-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-red-700  max-sm:text-xs"
            onClick={() => handleDelete(item)}
          >
            Delete
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleEventView(item)}
          >
            {item.event === "show" ? "Hide" : "Show"}
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleRewardView(item)}
          >
            {item.reward === "show" ? "Hide" : "Show"}
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleWithdrawStatus(item)}
          >
            {item.withdraw_status === "locked" ? (
              <div className="px-2">
                <img src={imgLocked} width="30px" alt="locked" />
                {/* <span className="pl-2">Locked</span> */}
              </div>
            ) : (
              <div className="px-2">
                <img src={imgUnlocked} width="30px" alt="unlocked" />
                {/* <span className="pl-2">Unlocked</span> */}
              </div>
            )}
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleCalcStatus(item)}
          >
            {item.calc_status === "show" ? "Hide" : "Show"}
          </button>
        </td>
        <td className="px-1">
          <button
            className="rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700  max-sm:text-xs"
            onClick={() => handleAnnounceStatus(item)}
          >
            {item.announce_status === "show" ? "Hide" : "Show"}
          </button>
        </td>
        <td className="px-1">
          <button
            className={clsx(
              "rounded-md bg-indigo-500 shadow-md shadow-gray-700 px-4 text-sm py-1 text-white hover:bg-indigo-700 w-full  max-sm:text-xs",
              {
                "bg-red-500 hover:bg-red-700":
                  item.trade_balance !== null &&
                  item.trade_balance !== undefined &&
                  item.trade_balance !== "" &&
                  item.trade_balance !== 0
              }
            )}
            onClick={() => handleTradeBalance(item)}
          >
            {item.trade_balance === null || item.trade_balance === undefined
              ? "0"
              : item.trade_balance}
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div className="font-sans text-lg font-normal max-sm:text-xs">
      <div className="m-10">
        <button
          className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-10 py-3 text-white hover:bg-blue-500"
          onClick={confirmBack}
        >
          Go to Dashboard
        </button>
      </div>
      <div className="w-full max-sm:w-auto m-auto mt-20 max-sm:mx-5 p-5 rounded-lg">
        <div className=" text-white w-full overflow-auto">
          <table className="table-auto w-full mb-3 max-sm:text-xs">
            <thead>
              <tr className="text-xs">
                <th>Client Name</th>
                <th>ETH Address</th>
                {/* <th>ETH Address</th> */}
                <th>Claim?</th>
                <th>Ad Image</th>
                <th>Ad Text</th>
                <th>Locked Text</th>
                <th>Set Profit 0.5%</th>
                <th>Set Profit 10%</th>
                <th>Set Profit 20%</th>
                <th>Set Profit 50%</th>
                <th>Account Removal</th>
                <th>Event Status</th>
                <th>Reward Status</th>
                <th>Withdraw Status</th>
                <th>How to Calculate</th>
                <th>Announce Status</th>
                <th>Trade Balance</th>
              </tr>
            </thead>
            <tbody className="text-center">{tblBody}</tbody>
          </table>
        </div>
        <div className="col-span-3 text-center mt-10">
          <button
            className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-10 py-3 text-white hover:bg-blue-500 "
            onClick={handleAdd}
          >
            Add New Client
          </button>
        </div>
      </div>
      <Modal
        openModal={clientAddModal}
        setOpenModal={setClientAddModal}
        children={<ClientAdd />}
        showClose={false}
      />
      <Modal
        openModal={adImageModal}
        setOpenModal={setAdImageModal}
        children={<AdImageSet />}
        showClose={true}
      />
      <Modal
        openModal={adTextModal}
        setOpenModal={setAdTextModal}
        children={<AdTextWrite />}
        showClose={false}
      />
      <Modal
        openModal={lockedTextModal}
        setOpenModal={setLockedTextModal}
        children={<LockedTextWrite />}
        showClose={false}
      />
      <Modal
        openModal={tradeBalanceModal}
        setOpenModal={setTradeBalanceModal}
        children={<GoldTradeBalance />}
        showClose={false}
      />
      <ToastContainer />
    </div>
  );
};

export default ClientsPanel;
