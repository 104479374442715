import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL
} from "firebase/storage";
import { getStores, saveStore, updateStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";

const UploadPanel = () => {
  const navigate = useNavigate();

  const [rewardStatus, setRewardStatus] = useState("");
  const [eventStatus, setEventStatus] = useState("");
  const [incomeStatus, setIncomeStatus] = useState("");
  const [announceStatus, setAnnounceStatus] = useState("");
  // const [transDate, setTransDate] = useState(
  // moment(Date.now()).format("YYYY-MM-DD hh:mm:ss")
  // );

  const confirmBack = () => {
    navigate("/admin_dashboard", { replace: true });
  };

  const setStatus = (status, content) => {
    switch (status) {
      case "reward":
        setRewardStatus(content);
        break;
      case "event":
        setEventStatus(content);
        break;
      case "income":
        setIncomeStatus(content);
        break;
      case "announcements":
        setAnnounceStatus(content);
        break;
      default:
        break;
    }
  };

  async function saveFileData(downloadURL, stat) {
    setStatus(stat, "Upload Finished!");
    const fileData = {
      fileName: stat + ".jpg",
      path: downloadURL
    };
    let flag = false;
    const fileAssetData = await getStores("FileAsset");
    if (IsEmpty(fileAssetData)) {
      flag = saveStore("FileAsset", fileData);
    } else {
      const fileAsset = Object.entries(fileAssetData).filter(
        (one) => one[1].fileName === stat + ".jpg"
      );
      if (IsEmpty(fileAsset)) {
        flag = saveStore("FileAsset", fileData);
      } else {
        flag = updateStore("FileAsset", fileAsset[0][1].id, fileData);
      }
    }
    if (flag) setStatus(stat, "Success!");
    else setStatus(stat, "Failure!");
  }

  async function handleUpload(storageRef, stat, file) {
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setStatus(stat, "Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            setStatus(stat, "Upload is paused");
            break;
          // case "running":
          //   setStatus(stat, "Upload is running");
          //   break;
          default:
            break;
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            saveFileData(downloadURL, stat);
          })
          .catch((error) => console.log(error));
      }
    );
  }

  async function confirmUpload(e, stat) {
    if (!e.target.files[0]) return;
    const storage = getStorage();
    const file = e.target.files[0];
    const storageRef = ref(storage, stat + ".jpg");

    // Delete the file
    // deleteObject(storageRef)
    //   .then(() => {
    //     setStatus(stat, "Original File deleted!");
    //     handleUpload(storageRef, stat, file);
    //   })
    //   .catch((error) => {
    //     handleUpload(storageRef, stat, file);
    //   });
    setStatus(stat, "File Deleting!");
    try {
      await deleteObject(storageRef);
    } catch (e) {}
    await handleUpload(storageRef, stat, file);
  }

  return (
    <div className="font-sans text-lg font-normal max-sm:text-xs">
      <div className="m-10">
        <button
          className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-10 py-3 text-white hover:bg-blue-500"
          onClick={confirmBack}
        >
          Go to Dashboard
        </button>
      </div>
      <div className="w-3/4 max-sm:w-3/4 bg-white m-auto mt-20 p-5 rounded-lg">
        <div className="grid grid-rows-4 grid-cols-4 text-black">
          <div className="p-3">Reward Url:</div>
          <div className="col-span-2">
            <input
              type="file"
              className="w-full rounded-sm border-b-black p-3"
              id="file_reward"
              // value={rewardUrl}
              onChange={(e) => confirmUpload(e, "reward")}
            />
          </div>
          <div className="p-3">{rewardStatus}</div>
          <div className="md:p-3 max-sm:py-3">Event Url:</div>
          <div className="col-span-2">
            <input
              type="file"
              id="file_event"
              className="w-full rounded-sm border-b-black p-3"
              // value={eventUrl}
              onChange={(e) => confirmUpload(e, "event")}
            />
          </div>
          <div className="p-3">{eventStatus}</div>
          <div className="md:p-3 max-sm:py-3">How to calculate income url:</div>
          <div className="col-span-2">
            <input
              type="file"
              id="file_calculateIncome"
              className="w-full rounded-sm border-b-black p-3"
              // value={calcIncomeUrl}
              onChange={(e) => confirmUpload(e, "income")}
            />
          </div>
          <div className="p-3">{incomeStatus}</div>
          <div className="md:p-3 max-sm:py-3">Announcements url:</div>
          <div className="col-span-2">
            <input
              type="file"
              id="file_announcements"
              className="w-full rounded-sm border-b-black p-3"
              // value={announcementsUrl}
              onChange={(e) => confirmUpload(e, "announcements")}
            />
          </div>
          <div className="p-3">{announceStatus}</div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UploadPanel;
