import React, { useEffect, useState } from "react";
import { IsEmpty } from "../utils/isEmpty";
import { getStores } from "../api/crud";
import { useSetRecoilState } from "recoil";
import { isTextPopupShow } from "../atom";

const AdText = ({ address }) => {
  const [adText, setAdText] = useState("");
  const setTextPopupShow = useSetRecoilState(isTextPopupShow);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientData = await getStores("Client");

        if (!IsEmpty(clientData)) {
          const clientUser = Object.entries(clientData).filter(
            (item) => item[1].address === address
          );

          if (clientUser.length > 0) {
            if (IsEmpty(clientUser[0][1]?.ad_text)) {
              setTextPopupShow(false);
              return;
            }
            setAdText(clientUser[0][1]?.ad_text);
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="font-sans text-lg font-normal max-sm:text-xs">
        <p className="sm:text-2xl text-sm text-black mb-2 font-bold">
          New release
        </p>
        <pre className="text-black p-2 whitespace-pre-wrap">{adText}</pre>
      </div>
    </div>
  );
};

export default AdText;
